import "./css/animate.css";
import "./css/bootstrap.css";
import "./css/color.css";
import "./css/elpath.css";
import './css/flaticon.css';
import './css/font-awesome-all.css';
import './css/jquery.fancybox.min.css';
import './css/nice-select.css';
import './css/owl.css';
import './css/responsive.css';
import './css/style.css';
import './css/switcher-style.css';
import './css/module-css/banner.css'
import './css/module-css/about.css';
import './css/module-css/blog-details.css';
import './css/module-css/booking-form.css';
import './css/module-css/cart.css';
import './css/module-css/category.css';
import './css/module-css/chooseus.css';
import './css/module-css/clients.css';
import './css/module-css/contact-info.css';
import './css/module-css/contact.css';
import './css/module-css/destination-details.css';
import './css/module-css/destinations.css';
import './css/module-css/error.css';
// import './css/module-css/faq.css';
import './css/module-css/instagram.css';
import './css/module-css/news-sidebar.css';
import './css/module-css/news.css';
import './css/module-css/offers.css';
import './css/module-css/page-title.css';
import './css/module-css/place.css';
import './css/module-css/pricing.css';
import './css/module-css/subscribe.css';
import './css/module-css/testimonial.css';
import './css/module-css/tour-details.css';
import './css/module-css/tour-sidebar.css';
import './css/module-css/tours.css';
import './css/module-css/video.css';
import Slider from 'react-slick';
import React, { useState,useEffect,useRef } from "react";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

// =====================
import ToursSection from "./TourSection";
import Video from "./Video";
import Carousel from "./Carousel";


export default function Home(){

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "TravelAgency",
    "name": "Shiv Travels66",
    "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
    "url": "https://www.shivtravels66.com",
    "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
        "addressLocality": "Kirtane Baug Hadpasar",
        "addressRegion": "Maharashtra",
        "postalCode": "411036",
        "addressCountry": "India"
    },
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9764768866",
        "contactType": "Customer Service",
        "availableLanguage": "English"
    },
    "email": "booking@shivtravels66.com",
    telephone:"+91 9764768866",
    image: "https://www.shivtravels66.com/OG Image-01.jpg",
    starRating: {
      "@type": "Rating",
      bestRating: "4.8",
      ratingValue: "3",
      worstRating: "1",
    },
    "keywords": [
      "Urbania On Rent in Pune",
      "Pune to Mahabaleshwar Urbania on Rent",
      "Pune to Shirdi Urbania on Rent",
      "Urbania Hire for Outstation Pune",
      "Urbania Hire for Corporate Events in Pune"
  ]

};

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };



  const faqData = [
    {
        "question": "What is the seating capacity of the Urbania bus?",
        "answer": "Our Force Urbania buses typically have a seating capacity of up to 17 passengers, making them perfect for family trips or small group outings to Shirdi."
      },
      {
        "question": "What amenities are included in the Urbania bus?",
        "answer": "Our Urbania buses come equipped with air conditioning, comfortable seating, charging ports, entertainment systems, and ample luggage space to ensure a pleasant journey."
      },
      {
        "question": "How do I book the Urbania for my trip to Shirdi?",
        "answer": "You can easily book the Urbania by contacting us through our website or by calling our customer service number. Provide your travel details, and we will assist you with the booking process."
      },
      {
        "question": "Are there any additional charges I should be aware of?",
        "answer": "We provide transparent pricing with no hidden fees. Any additional charges, such as for extra stops or extended hours, will be discussed and agreed upon during the booking process."
      },
      {
        "question": "Is there a cancellation policy?",
        "answer": "Yes, we have a cancellation policy in place. Please check our website or contact our customer service team for detailed information on cancellation terms and conditions."
      },
      {
        "question": "Can I customize my itinerary for the trip?",
        "answer": "Absolutely! We offer customizable itineraries based on your preferences. Whether you want to make extra stops along the way or visit nearby attractions, we can accommodate your requests."
      },
      {
        "question": "What are the payment options available?",
        "answer": "We offer various payment options, including cash, credit/debit cards, and online payment methods. You can choose the option that is most convenient for you during the booking process."
      },
      {
        "question": "How long does the journey from Pune to Shirdi take?",
        "answer": "The journey from Pune to Shirdi typically takes around 5 to 6 hours, depending on traffic conditions and the chosen route. Our drivers will ensure a timely arrival at your destination."
      },
      {
        "question": "What should I do if I have a special request (e.g., child seats or accessibility needs)?",
        "answer": "Please inform us of any special requests during the booking process. We strive to accommodate all passenger needs, including child seats and accessibility requirements."
      },
      {
        "question": "Is your service available for other destinations as well?",
        "answer": "Yes, in addition to Pune to Shirdi, we offer Urbania rentals for various destinations, including other pilgrimage sites and tourist attractions in Maharashtra. Feel free to inquire about our services for your desired location!"
      }
  ];

  const testimonialSetting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


const images = [
    
  'real/customer/shivtarvels66-owner.jpg',
  'real/customer/shivtravel66-customer1.jpg',
  'real/customer/shivtravels66-customer.jpg',
  'real/customer/shivtravels66-customer2.jpg',
  'real/customer/shivtravels66-customer3.jpg',
  'real/customer/shivtravels66-customer5.jpg',
  'real/customer/shivtravels66-customer6.jpg',
  'real/customer/shivtravels66-cutomer4.jpg',
  'real/gallery/urbania-shiv.jpg',
  'real/gallery/urbania1.jpg',
  'real/gallery/tempo-traveller-seats.jpg',
  'real/gallery/shiv-travels-crysta.jpg'
];

  // ================ Testimonial Section ===========
  const testimonialsData = [
    {
      name: "Mr.Rahul Patil.",
      img: "testimonial/testG1.png",
      feedback:
        "Shiv Travels66 made our trip unforgettable! The service was exceptional, and the driver was friendly and professional. Highly recommend!",
    },
    {
      name: "Mrs.Rakhi Sawant",
      img: "testimonial/testL1.png",
      feedback:
        "I booked a family vacation with Shiv Travels66, and everything was perfectly organized. From pick-up to drop-off, it was smooth sailing!",
    },
    {
      name: "Mr.Raj Patel.",
      img: "testimonial/testG2.png",
      feedback:
        "What a wonderful experience! The team at Shiv Travels66 was attentive to our needs and ensured we had a great time exploring the sights.",
    },
    {
      name: "Mrs.Sakshi Desai.",
      img: "testimonial/testL3.png",
      feedback:
        "I can't thank Shiv Travels66 enough for their excellent service. The vehicle was clean and comfortable, and our driver was very knowledgeable about the area.",
    },
    {
      name: "Mr.Gaurav Naik.",
      img: "testimonial/testG3.png",
      feedback:
        "Shiv Travels66 offers top-notch service at great prices. I felt valued as a customer, and they went above and beyond to make our trip special.",
    },
    {
      name: "Mrs.Reema Shekh.",
      img: "testimonial/testL4.png",
      feedback:
        "From start to finish, Shiv Travels66 provided an amazing experience. The booking process was easy, and the team was always ready to assist.",
    },
    {
      name: "Mr.Raj Kolahtkar.",
      img: "testimonial/testG5.png",
      feedback:
        "Shiv Travels66 made our business trip seamless. They were punctual, reliable, and made sure we arrived on time for all our meetings.",
    },
    
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
    const ContentBox = () => (
        <div className="content-box">
          <span className="title-text">Discover the Planet...</span>
          <h2>Creating memories through adventure sports</h2>
          <p>This statistic is based on our average personal current account online</p>
          <BookingForm />
        </div>
      );
      
      const BookingForm = () => (
        <div className="booking-form">
          <div className="booking-inner">
            <form method="post" action="index.html" className="clearfix">
              <FormGroup icon="icon-8" placeholder="Your Destination" type="select" options={destinationOptions} />
              <FormGroup icon="icon-10" placeholder="When to Start" type="input" />
              <FormGroup icon="icon-11" placeholder="How many Guests" type="select" options={guestOptions} />
              <div className="btn-box">
                <button type="submit">Find Now</button>
              </div>
            </form>
          </div>
        </div>
      );
      
      const FormGroup = ({ icon, placeholder, type, options }) => (
        <div className="form-group">
          <div className="icon">
            <i className={icon}></i>
          </div>
          {type === 'input' ? (
            <input type="text" name="date" id="datepicker" placeholder={placeholder} />
          ) : (
            <div className="select-box">
              <select className="wide">
                <option data-display={placeholder}>{placeholder}</option>
                {options?.map((option, idx) => (
                  <option key={idx} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      );
      
      const ImageBox = () => (
        <div className="image-box ml_40">
          <figure className="image image-1 mb_30">
            <img src="images/banner/banner-1.jpg" alt="Banner 1" />
          </figure>
          <figure className="image image-2">
            <img src="images/banner/banner-img-2.jpg" alt="Banner 2" />
          </figure>
          <figure className="image image-3">
            <img src="images/banner/banner-img-3.jpg" alt="Banner 3" />
          </figure>
          <div className="curve-text">
            <div className="icon-box">
              <img src="images/icons/icon-3.png" alt="Icon" />
            </div>
            <span className="curved-circle">
              The Best Agency &nbsp;&nbsp; - &nbsp;&nbsp; The Best Agency &nbsp;&nbsp; - &nbsp;&nbsp;
            </span>
          </div>
        </div>
      );
      
      const destinationOptions = [
        { value: '1', label: 'USA' },
        { value: '2', label: 'Italy' },
        { value: '3', label: 'China' },
        { value: '4', label: 'Thailand' },
      ];
      
      const guestOptions = [
        { value: '1', label: '1 Child 2 Adult' },
        { value: '2', label: '2 Child 2 Adult' },
        { value: '3', label: '1 Child 3 Adult' },
        { value: '4', label: '2 Child 3 Adult' },
      ];

      const offersData = [
        {
          delay: "00ms",
          backgroundImage: "img/13 Seater tempo Traveller.jpg",
          label: "13 Seater tempo Traveller",
          title: "40%",
          description: "For Travel Packages",
          link: "tour-details.html"
        },
        {
          delay: "300ms",
          backgroundImage: "images/shape/shape-8.png",
          label: "Exclusive Deal",
          title: "Buy 1 get 1",
          description: "For air Tickets",
          link: "tour-details.html"
        },
        {
          delay: "600ms",
          backgroundImage: "images/shape/shape-9.png",
          label: "Discount up to",
          title: "50%",
          description: "Hotels and Trips",
          link: "tour-details.html"
        }
      ];


      const OfferBlock = ({ offer }) => (
        <div className="col-lg-4 col-md-6 col-sm-12 offers-block">
          <div
            className="offers-block-one wow fadeInUp animated"
            data-wow-delay={offer.delay}
            data-wow-duration="1500ms"
          >
            <div className="inner-box" style={{ backgroundImage: `url(${offer.backgroundImage})` }}>
              <span>{offer.label}</span>
              <h2>{offer.title}</h2>
              <p>{offer.description}</p>
              <a href={offer.link}>View Details</a>
            </div>
          </div>
        </div>
      );

      const PlaceBlock = ({ destination }) => (
        <li>
          <div className="place-block-two">
            <div className="inner-box">
              <figure className="image-box">
                <img src={destination.image} alt={destination.name} />
              </figure>
              <h4>
                <a href={destination.link}>{destination.name}</a>
              </h4>
            </div>
          </div>
        </li>
      );

      const destinations = [
        { name: "South Asia", image: "images/resource/place-7.jpg", link: "destination-details.html" },
        { name: "Atlantic Ocean", image: "images/resource/place-8.jpg", link: "destination-details.html" },
        { name: "Middle East", image: "images/resource/place-9.jpg", link: "destination-details.html" },
        { name: "Balkan Countries", image: "images/resource/place-10.jpg", link: "destination-details.html" },
        { name: "Australia", image: "images/resource/place-11.jpg", link: "destination-details.html" },
        { name: "Antarctica", image: "images/resource/place-12.jpg", link: "destination-details.html" }
      ];


      const pricingPackages = [
        
        {
          title: '13 Seater Urbania bus',
          img: 'real/gallery/urbania1.jpg',
        },
        {
          title: '17 Seater Urbania Bus',
          img: 'real/gallery/urbania2.jpg',
        },
        {
          title: '17 Seater Tempo Traveller',
          img: 'real/gallery/shiv-travels-tempo-traveller.jpg',
        },
       
        {
          title: '13 Seater tempo Traveller',
         
          img: 'real/gallery/shivtravels-tempotravellers1.jpg',
        },
       {
          title: 'Innova Crysta',
          img: 'real/gallery/innova-crysta.jpg',
        },
        {
          title: 'Urbania',
          img: 'real/gallery/urbania3.jpg',
        },
      ];
      

    
      const testimonials = [
        {
          id: 1,
          text: "This service was fantastic! Highly recommend.",
          author: "John Doe"
        },
        {
          id: 2,
          text: "A wonderful experience from start to finish!",
          author: "Jane Smith"
        },
        {
          id: 3,
          text: "I couldn't be happier with the results!",
          author: "Alice Johnson"
        },
        {
          id: 4,
          text: "Excellent customer support and great value!",
          author: "Bob Brown"
        },
      ];

      const newsItems = [
  
    
      {
         
        title: "Urbania",
        image: '/real/urbania-shivtravels.jpg',
        para:'The 13-Seater Urbania is designed for small groups and family trips, offering a blend of comfort and efficiency. Its spacious interior provides ample legroom and high-back seating, ensuring a pleasant journey for all passengers.',
        rate:'Per km Rate AC -  36 Rs.',
        
      
    },
    {
         
      title: "Tempo Traveller",
      image: 'real/tempo-traveller-shiv-travels.jpg',
      para:'Tempo Travellers are multi-purpose vehicles that resemble minibuses, but have a capacity of between 10 and 30 people. It comes with additional features that are perfect for the medium-sized group and therefore affordable.',
      rate:'Per km Rate AC - 26 Rs.',
      rate1:'Per km Rate Non-Ac 23 Rs.'
    
  },
         
    {
            
      title: "Innova Crysta",
      image: 'real/innova-crysta-shivtravels.jpg',
      para:'The Innova Crysta is a versatile and spacious MPV that offers exceptional comfort and advanced features for both driver and passengers. With its premium interior and powerful engine options, the Innova Crysta is perfect for family outings and long journeys.',
      rate:'Per km Rate - 19 Rs.'
      
  },
    ];
    const newsItems1 = [
      
      {
          
          title: "Corporate Travel Solutions",
          image: 'services/coprate.png',
          link: "Urbania-Hire-for-Corporate-Events-in-Pune",
          para:'Corporate travel solutions provided by cab services typically include a range of features and services tailored to meet the needs of businesses and their employees.'
      },
      {
          category: "Europe",
          date: "18 Mar, 2023",
          title: "24/7 Customer Service",
          image: 'services/Customer Support.png',
          link: "Tempo-Traveller-Hire-for-Wedding-in-Pune",
          para:'Assistance with booking cabs at any hour, whether online, through an app, or via phone.Support for changes to your itinerary, such as rescheduling pickup times etc.'
      },
      {
         
          title: "Experienced Drivers",
          image: 'services/experinDriver (1).png',
          link: "Mini-Bus-On-Rent-in-Pune",
          para:'Our professional drivers undergo rigorous training to ensure your safety and comfort, with in-depth knowledge of routes and destinations, navigating any road with ease.'
      },
      {
         
          title: "GPS Tracking for Real-Time Updates",
          image: 'services/gps tracking.png',
          link: "Online-Cab-Booking-Pune",
          para:'Our vehicles are equipped with GPS tracking, providing you with real-time updates on your journey. Stay informed about your travel progress and reach your destination with confidence.'
      },
      {
          category: "Europe",
          date: "18 Mar, 2023",
          title: "Clean and Well-Maintained Vehicles",
          image: 'services/cleanvehcle.png',
          link: "Innova-Crysta-on-Rent-in-Pune",
          para:'Cleanliness and vehicle maintenance are paramount to us. Our fleet is regularly serviced and sanitized to provide you with a comfortable and hygienic travel experience.'
      },
    
      {
          category: "Europe",
          date: "18 Mar, 2023",
          title: "Customized Travel Packages",
          image: 'services/travelpackage.png',
          link: "Pune-Tempo-Traveller-Hire-for-Outstation",
          para:'Tailor your travel experience to your preferences with our customized packages, whether its a solo adventure, family vacation, or romantic getaway, exceeding your expectations.'

      },
  ];


  const cars = [
    { name: "Urbania On Rent in Pune", img: "img/Urbania-on-rent-in-pune.jpg",para:"Experience comfort, luxury, and convenience with Shiv Travels66. Our Urbania rental services in Pune are designed to provide you with a premium travel experience at affordable rates. Contact us today to book your Urbania and make your journey memorable." },
    { name: "Pune to Mahabaleshwar Urbania on Rent", img: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",para:'Planning a trip from Pune to Mahabaleshwar and need comfortable and reliable group transportation? Shiv Travels66 offers Urbania on rent for a seamless travel experience.' },
    { name: "Pune to Shirdi Urbania on Rent", img: "img/Pune-to-Shirdi-Urbania-on-Rent.jpg",para:'Look no further than Shiv Travels66 for your transportation needs! Our Pune to Shirdi Urbania on rent service offers a luxurious and comfortable travel experience that makes your journey as memorable as your destination.' },
    { name: "Urbania Hire for Outstation Pune", img: "img/Urbania-Hire-for Outstation-Pune.jpg",para:'When planning an outstation trip from Pune, comfort and convenience should be your top priorities. At Shiv Travels66, we offer Urbania hire for outstation Pune, providing a luxurious travel experience that ensures you reach your destination safely and in style. ' },
    // ============
    { name: "Tempo Traveller Hire for Wedding in Pune", img: "img/Tempo-Traveller Hire-for-Wedding in-Pune.jpg",para:'Weddings are grand celebrations filled with joy, love, and togetherness, and every detail matters—especially transportation. At Shiv Travels66, we understand the importance of seamless travel during your special day.' },
    { name: "Pune to Ujjain Urbania On Rent", img: "img/Pune to ujjain.jpg",para:'Look no further! Shiv Travels66 offers exceptional Urbania rentals tailored for your journey. Our comfortable and spacious vehicles ensure a smooth ride as you explore the sacred city of Ujjain. ' },
    { name: "Pune to Goa Urbania On Rent", img: "img/Pune to Goa.jpg",para:'Look no further! Shiv Travels66 offers Urbania rentals that provide a luxurious and comfortable travel experience for your journey. Whether youre traveling with family, friends, or colleagues, our Urbania buses are the perfect choice for a memorable trip. ' },
  ];

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
          breakpoint: 1024, // Tablet
          settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
          },
      },
      {
          breakpoint: 400, // Mobile
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
          },
      },
  ],

  };



  const cards = [
    {  img: "real/card/collection.JPG" },
    { img:'real/card/gallery7.JPG' },
    { img: "real/card/group1.JPG" },
    {img:'real/card/group2.JPG' },
    {img:'real/card/group3.JPG'},
    {img:'real/card/seates.jpeg'},

    {img:'real/card/tempo trav1.jpeg'},
    {img:'real/card/tempo traveller.jpeg'},
    {img:'real/card/urbani1.JPG'},
    {img:'real/card/urbania seates.JPG'}
];

const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000, 
  pauseOnHover: false, 
  arrows: false,
};


    return(
        <>
           <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages starting at ₹299." />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and realx free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing beach vacation with our exclusive packages." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
 

    {/* <div>
          <Slider {...settingsSlider}>
            <div className="myslider">
              <img src="img/Slider/Slider1.jpg" alt="slide1" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider2.jpg" alt="slide 2" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider3.jpg" alt="slide 3" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider4.jpg" alt="slide 4" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider5.jpg" alt="slide 5" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider6.jpg" alt="slide 6" />
            </div>
            <div className="myslider">
              <img src="img/Slider/Slider7.jpg" alt="slide 7" />
            </div>

          </Slider>
        </div>
      */}

      <Carousel></Carousel>

{/* ================================ */}
          <section className="pricing-section pt_40 pb_40">
      <div className="pattern-layer">
        <div className="pattern-1" style={{ backgroundImage: 'url(images/shape/shape-14.png)' }}></div>
        <div className="pattern-2" style={{ backgroundImage: 'url(images/shape/shape-15.png)' }}></div>
      </div>
      <div className="container">
        <div className="sec-title mb_50 centred">
          <span className="sub-title">Vehicle</span>
          <h2>Our fleet</h2>
        </div>
        <div className="inner-container">
          <div className="row clearfix">
            {pricingPackages.map(({ title, packageName, price, img }, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                <div className="pricing-block-one">
                  <div className="pricing-table">
                    <figure className="image-box">
                      <img src={img} alt={packageName} />
                    </figure>
                    <div className="content-box">
                      <h6>{title}</h6>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>  
   
       <section className="pricing-section pt-3 pb-3 container">
      <h1 className="text-center">Popular Destination</h1>
<div className="border-line"></div>
    <div className=" container slider-container">
   
      <Slider {...settings1}>
        {cars.map((car, index) => (
          <div key={index} className="slide text-center">
            <div className="innerimg m-2">
              <div className="cardbox">
            <div className="service-imgbox">
                <img src={car.img} alt={car.name} className="m-auto"/>
            </div>
           
            <h5 className="pt-2 pb-2">{car.name}</h5>
            <p>{car.para}</p>
         
            <a href="https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Force Urbania/ Tempo Traveller/ Innova Crysta/ Mini Bus With Shiv Travels Request you Please Send More Details or Call Me Thank you . ."  target="_blank" className="read-more1">
                                                        Book Now 
                                                        </a>
          </div>
          </div>
          </div>
        ))}
      </Slider>
    </div>
   </section>   
  
<section className="news-section pt_100 pb_65">
            <div className="container">
                <div className="sec-title centred mb_50">
                    <span className="sub-title">Shiv Travels</span>
                    <h2>Offers travel services in Pune</h2>
                </div>
                <div className="row clearfix container-fluid">
                    {newsItems.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-block" key={index}>
                            <div className="news-block-one wow fadeInUp animated" data-wow-delay={`${index * 300}ms`} data-wow-duration="1500ms">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <a href={item.link}><img src={item.image} alt={item.title} /></a>
                                    </figure>
                                    <div className="lower-content text-center">
                                       
                                        <h3><a href={item.link}>{item.title}</a></h3>
                                        <p className="colorblue">{item.rate}</p>
                                        <p  className="colorblue">{item.rate1}</p>
                                        <p>{item.para}</p>
                                        <div className="mt-2">
                                          
                                             <a href="https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Force Urbania/ Tempo Traveller/ Innova Crysta/ Mini Bus With Shiv Travels Request you Please Send More Details or Call Me Thank you ." target="_blank" className="text-decoration book-button">Book Now</a>
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>  

   {/* =========================== FAQ Section ================= */}
     <section className="pricing-section  pb_50 faq-section">
   <div className="faq-container">
      <h2 className="text-center pb-4">FAQs</h2>
      <div className="faq-grid">
        <div className="faq-column">
          {faqData.slice(0, 5).map((faq, index) => (
            <div key={index} className="faq-item">
              <p
                className="faq-question" 
                onClick={() => handleToggle(index)}
              >
                {faq.question}
              </p>
              {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
        <div className="faq-column">
          {faqData.slice(5, 10).map((faq, index) => (
            <div key={index + 5} className="faq-item">
              <p
                className="faq-question" 
                onClick={() => handleToggle(index + 5)}
              >
                {faq.question}
              </p>
              {activeIndex === index + 5 && <p className="faq-answer">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div></section>  


   
        

         <section className="news-section pt_100 pb_65">
            <div className="container">
                  <h1 className="text-center service-head">Our Services</h1>
                <div className="row clearfix container-fluid">
                    {newsItems1.map((item, index) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-block" key={index}>
                            <div className="news-block-one wow fadeInUp animated" data-wow-delay={`${index * 300}ms`} data-wow-duration="1500ms">
                                <div className="inner-box myinnerbox">
                                    <figure className="image-box">
                                        <a href={item.link}><img src={item.image} alt={item.title} /></a>
                                    </figure>
                                    <div className="lower-content service-background">
                                        
                                        <h3><a href={item.link}>{item.title}</a></h3>
                                        <p>{item.para}</p>
                                        
                                        <div className="read-btn">
                                        <Link to={item.link}className="">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section> 
        
         {/* ========================= Testimonial Section ========================== */}
  

    

           <section className="testimonial mytestimonial-section testimonialsection py-100">
        <div className="container">
          <h1 className="text-center text-white pb-4">Testimonial</h1>
          <div className="row container">
            <div className="col-md-10 offset-md-1">
              <Slider {...testimonialSetting}>
                {testimonialsData.map((testimonial, index) => (
                  <div className="item-box" key={index}>
                    <div className="text-box text-center">
                      {testimonial.feedback}
                    </div>
                    <div className="item-name text-center">
                      <i className="flaticon-left-quote"></i>
                      <div className="testimonial-img m-auto">
                        <img src={testimonial.img} alt="Testimonial Images" />
                      </div>

                      <h5>{testimonial.name}</h5>
                      <span>{testimonial.designation}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section> 
  
        <h1 className="text-center pb-3 pt-4"><span className="colorblue">Our Happy Customer</span></h1>
        <div className="gallery">
         
            {images.map((img, index) => (
                <div className="gallery-item" key={index}>
                    <img src={img} alt={`Gallery item ${index + 1}`} className="galleryimg" />
                </div>
            ))}
        </div> 
      
      

          <section class="features-section container">
                <h2>Why We’re Your <span class="textyellow">Best Ride</span></h2>
        
                <div class="features-container">
                    <div class="feature-box1">
                    <div class="icon">
                        🌍
                    </div>
                        <h3 class="feature-title">24/7 Availability</h3>
                        <p class="feature-description">We provide round-the-clock Trvels services ensuring you can travel anytime, anywhere without hassle.</p>
                    </div>
       
                    <div class="feature-box1">
                        <div class="icon">
                            💰
                        </div>
                        <h3 class="feature-title">Affordable Rates</h3>
                        <p class="feature-description">Our pricing is transparent and pocket-friendly, making it affordable for everyone to travel in comfort.</p>
                    </div>
      
                    <div class="feature-box1">
                    <div class="icon">
                         🚐
                      </div>
            
                        <h3 class="feature-title">Luxury Trvels</h3>
                        <p class="feature-description">Choose from our wide range of luxury vehicles to travel in style and comfort for all your special occasions.</p>
                    </div>
                </div>
            </section>  

    

        <div className="carousel1">
            <div className="carousel-inner1">
                {cards.map((card, index) => (
                    <div className="carousel-item1" key={index}>
                       <img src={card.img} alt="img"/>
                    </div>
                ))}
            </div>
        </div>  

        {/* <Video></Video> */}

    
    

        </>
    )
}