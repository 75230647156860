import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function InnovaCrystaCab() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently hired an Innova Crysta cab from Shiv Travels66 for a family trip to Mahabaleshwar, and I couldn't be happier with the service! The cab was clean, comfortable, and well-maintained. Our driver was not only punctual but also very friendly and knowledgeable about the area. He made our journey enjoyable and stress-free. I highly recommend Shiv Travels66 for anyone looking for reliable cab services in Pune!",
    name: "Mrs. Anjali Deshmukh"
  },
  {
    quote: "Our experience with Shiv Travels66 was fantastic! We booked the Innova Crysta for a wedding event, and it was perfect for transporting our guests. The cab was spacious and luxurious, making everyone feel comfortable during the ride. The driver was courteous and ensured everyone reached the venue on time. I will definitely choose Shiv Travels66 again for future trips!",
    name: "Mr.Rajesh Kumar"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            question: "What is the capacity of an Innova Crysta cab?",
            answer: "The Innova Crysta cab can comfortably seat up to 7 passengers, making it ideal for family trips, group outings, or corporate travel."
        },
        {
            question: "Is the Innova Crysta cab available for outstation travel?",
            answer: "Yes, we offer Innova Crysta cabs for both local and outstation travel. You can book the cab for destinations across Maharashtra and beyond."
        },
        {
            question: "What amenities are included in the Innova Crysta cab?",
            answer: "Our Innova Crysta cabs come equipped with air conditioning, comfortable seating, a music system, and ample luggage space. Some vehicles may also have additional features like charging points and Wi-Fi."
        },
        {
            question: "How do I book an Innova Crysta cab with Shiv Travels66?",
            answer: "You can book an Innova Crysta cab through our website, mobile app, or by contacting our customer support team. Just provide your travel details, and we'll assist you with the booking process."
        },
        {
            question: "What are the payment options available for booking?",
            answer: "We accept various payment methods, including online payments, bank transfers, and cash payments. You can choose the option that is most convenient for you."
        },
        {
            question: "What are the cancellation policies for the Innova Crysta cab?",
            answer: "We offer flexible cancellation policies. Please refer to our terms and conditions for specific details regarding cancellation fees and timelines."
        },
        {
            question: "Are there any additional charges for outstation travel?",
            answer: "Yes, outstation travel may incur additional charges based on distance, duration, and any specific requests. Our team will provide a clear breakdown of costs when you book."
        },
        {
            question: "Are the drivers experienced and well-trained?",
            answer: "Absolutely! Our drivers are professional, experienced, and well-trained to ensure your safety and comfort during the journey."
        },
        {
            question: "Can I request a specific pick-up and drop-off location?",
            answer: "Yes, you can specify your preferred pick-up and drop-off locations while booking your Innova Crysta cab. We strive to accommodate your needs as much as possible."
        },
        {
            question: "Is the Innova Crysta cab sanitized before every trip?",
            answer: "Yes, we prioritize your safety. All our vehicles, including the Innova Crysta cabs, are thoroughly sanitized before each trip to ensure a clean and safe environment for our passengers."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const vehicles = [
      {
        type: ' Force Urbania ',
        capacity: '13-Seater/ 17-Seater',
        rates: 'Rs.10,800',
        perDay: 'Rs.36/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
      },
      {
        type: 'Tempo Traveller AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.7,800',
        perDay: 'Rs.26/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
      }, 
      {
        type: 'Tempo Traveller Non-AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.6,900',
        perDay: 'Rs.23/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
      }, 
      {
        type: 'Innova Crysta',
        capacity: '6+1 Seater',
        rates: 'Rs.5,700',
        perDay: '19/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
      },
    ];


    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.7",
        ratingValue: "3",
        worstRating: "1.2",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
  

   
  return (
    <>
    <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Innova Crysta Cab | Online Cab Booking Pune
             | Innova On Rent in Pune | Pune to Mahabaleshwar Innova Crysta Cab</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Innova Crysta Cab | Online Cab Booking Pune |Innova On Rent in Pune | Pune to Mahabaleshwar Innova Crysta Cab" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Innova Crysta Cab</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Innova-Crysta Cab.jpg" alt="Innova Crysta Cab" />
              </div>

              <div>
              <h1>Innova Crysta Cab: The Ultimate Travel Experience with Shiv Travels66</h1>
<p>When it comes to comfortable and reliable transportation, the Innova Crysta cab stands out as an excellent choice for travelers in Pune and beyond. At Shiv Travels66, we offer premium Innova Crysta cabs that are perfect for a variety of occasions, including family trips, corporate outings, and pilgrimages. With a perfect blend of luxury, space, and performance, our Innova Crysta cabs provide an unparalleled travel experience.</p>

<h2>Innova Crysta Cab Pune</h2>
<p>When you need a reliable and spacious vehicle in Pune, our Innova Crysta cabs are just a booking away. Designed for comfort, these cabs are ideal for both short and long journeys, making them a favorite among travelers.</p>

<h2>Innova Crysta Taxi in Pune</h2>
<p>Our Innova Crysta taxi service in Pune ensures that you travel in style and comfort. With plush interiors, advanced climate control, and modern entertainment systems, you’ll enjoy every moment of your journey. Our drivers are well-trained and experienced, ensuring a smooth and safe ride.</p>

<h2>Innova Crysta Outstation Charges</h2>
<p>Traveling out of Pune? We offer competitive Innova Crysta outstation charges that are designed to suit your budget. Whether you’re heading to popular destinations like Shirdi, Mahabaleshwar, or Lonavala, we provide transparent pricing with no hidden fees.</p>

<h2>Innova Crysta Cab Booking</h2>
<p>Booking an Innova Crysta cab with Shiv Travels66 is simple and convenient. You can easily make your reservation online through our website or contact our customer service team for assistance. We ensure prompt confirmation and flexible booking options to cater to your travel plans.</p>

<h2>Crysta Car Taxi</h2>
<p>Our Crysta car taxi service combines luxury with functionality. The Innova Crysta is not just a taxi; it’s an experience. Whether you’re traveling for business or leisure, our cabs offer the best in class comfort and convenience.</p>

<h2>Innova Car Taxi</h2>
<p>Looking for an Innova car taxi? Look no further! Our fleet of Innova Crysta cabs is maintained to the highest standards, ensuring that you have a safe and enjoyable ride every time you choose us for your travel needs.</p>

<h2>Innova Crysta for Taxi</h2>
<p>The Innova Crysta for taxi service is perfect for those who require a spacious and luxurious vehicle for group travel. With ample seating and storage space, it is ideal for family trips, weddings, and other events.</p>

<h2>Crysta Taxi on Rent in Pune</h2>
<p>Need a Crysta taxi on rent in Pune? We offer flexible rental options tailored to your needs. Whether you need the cab for a few hours or several days, our rental service is designed to accommodate your schedule and budget.</p>

<h2>Innova Crysta Per Km Rate for Outstation</h2>
<p>We offer a competitive Innova Crysta per km rate for outstation travel, making it easy for you to plan your trips without worrying about unexpected costs. Our pricing is straightforward, and we provide detailed quotes based on your travel itinerary.</p>

<h2>Innova Crysta Taxi Booking</h2>
<p>Booking an Innova Crysta taxi has never been easier! Our user-friendly online booking system allows you to reserve your vehicle in just a few clicks. Choose your pickup and drop-off locations, select the vehicle type, and confirm your booking. You can also reach out to our customer support team for assistance at any stage of the booking process.</p>

<h2>Innova Crysta Taxi Price</h2>
<p>We offer competitive Innova Crysta taxi prices that cater to various budgets. Our pricing structure is transparent, with no hidden fees, ensuring that you get the best value for your money.</p>

<h2>Innova Crysta Taxi Charges</h2>
<p>Our Innova Crysta taxi charges are designed to be affordable while maintaining the highest quality of service. We pride ourselves on offering economical rates without compromising on the comfort and luxury that the Innova Crysta provides.</p>

<h2>Innova Crysta Taxi Price Per Km</h2>
<p>We understand that budget management is crucial when planning your trips. That’s why we provide a clear Innova Crysta taxi price per km. Our per-kilometer rates are competitive and straightforward, allowing you to estimate your travel costs accurately.</p>

<h2>Innova Crysta Taxi Rate</h2>
<p>At Shiv Travels66, we believe in offering fair and competitive Innova Crysta taxi rates. Our rates vary based on the distance, duration, and destination, ensuring that you receive an economical and customized travel solution that fits your needs.</p>

<h2>Innova for Rent Outstation</h2>
<p>Looking for an Innova for rent outstation? Our fleet of Innova Crysta vehicles is available for outstation bookings at affordable rates. Experience the comfort of traveling in a well-maintained vehicle, equipped with all the amenities you need for a pleasant journey.</p>

<h2>Taxi Innova Crysta</h2>
<p>Our Taxi Innova Crysta service guarantees a smooth and comfortable ride. With spacious seating, ample luggage space, and a reliable driver, your journey will be enjoyable whether you're traveling with family or colleagues.</p>

<h2>Toyota Innova Crysta Taxi Price</h2>
<p>If you're specifically looking for Toyota Innova Crysta taxi prices, we offer competitive rates for this premium vehicle. The Toyota Innova Crysta is known for its reliability and comfort, making it an excellent choice for both short and long trips.</p>

<h2>Toyota Innova Crysta Taxi</h2>
<p>As a leading provider of Toyota Innova Crysta taxis, we ensure that our vehicles are maintained to the highest standards. Our cabs come equipped with modern amenities, and our professional drivers are dedicated to providing a safe and pleasant travel experience.</p>

              </div>

             

              <div>
      <h1>Innova Crysta Cab on Rent with Shiv Travels66 Contact Number:- </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Innova Crysta Cab on Rent. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>Innova Crysta Cab Pune</p>
<p>Innova Crysta Taxi in Pune</p>
<p>Innova Crysta Outstation Charges</p>
<p>Innova Crysta Cab Booking</p>
<p>Crysta Car Taxi</p>
<p>Innova Car Taxi</p>
<p>Innova Crysta for Taxi</p>
<p>Crysta Taxi on Rent in Pune</p>
<p>Innova Crysta Per Km Rate for Outstation</p>



          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>Innova Crysta Taxi Booking</p>
<p>Innova Crysta Taxi Price</p>
<p>Innova Crysta Taxi Charges</p>
<p>Innova Crysta Taxi Price Per Km</p>
<p>Innova Crysta Taxi Rate</p>
<p>Innova for Rent Outstation</p>
<p>Taxi Innova Crysta</p>
<p>Toyota Innova Crysta Taxi Price</p>
<p>Toyota Innova Crysta Taxi</p>

          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Innova Crysta Cab with Shiv Travels66?</h1>
<p>When it comes to hiring an Innova Crysta cab, choosing the right service provider can make all the difference in your travel experience. Here are some compelling reasons to choose Shiv Travels66 for your Innova Crysta cab needs:</p>

<h2>1. Premium Comfort and Style</h2>
<p>Our Innova Crysta cabs are designed for maximum comfort, featuring spacious interiors, luxurious seating, and modern amenities. Whether you’re traveling for business or leisure, our cabs ensure a smooth and enjoyable ride.</p>

<h2>2. Well-Maintained Fleet</h2>
<p>We take pride in our fleet of Innova Crysta vehicles, which are regularly maintained and serviced to ensure safety and reliability. Our cars are equipped with the latest technology and safety features, providing peace of mind during your journey.</p>

<h2>3. Professional Drivers</h2>
<p>Our experienced and professional drivers are well-trained, courteous, and familiar with the best routes to your destination. They prioritize your safety and comfort, ensuring a hassle-free travel experience.</p>

<h2>4. Transparent Pricing</h2>
<p>At Shiv Travels66, we believe in transparent pricing with no hidden fees. Our competitive rates for Innova Crysta cabs are designed to fit various budgets while delivering excellent value for money.</p>

<h2>5. Flexible Booking Options</h2>
<p>We offer easy and flexible booking options, allowing you to reserve your Innova Crysta cab online or through our customer support team. You can choose from different packages based on your travel needs, whether it's a one-way trip or an extended rental.</p>

<h2>6. 24/7 Customer Support</h2>
<p>Our dedicated customer support team is available 24/7 to assist you with any inquiries or changes to your booking. We are committed to providing exceptional service and support at every step of your journey.</p>

<h2>7. Tailored Travel Solutions</h2>
<p>Whether you need a cab for a corporate event, family trip, or airport transfer, we offer tailored travel solutions to meet your specific requirements. Our Innova Crysta cabs can accommodate various group sizes and travel plans.</p>

<h2>8. Positive Customer Reviews</h2>
<p>We have a strong reputation for reliability and customer satisfaction. Our positive testimonials from previous clients reflect our commitment to providing high-quality service and a memorable travel experience.</p>

<h2>9. Convenient Locations</h2>
<p>With convenient pick-up and drop-off locations, we make it easy for you to start and end your journey. Our services are available throughout Pune and beyond, ensuring accessibility wherever you need to go.</p>

<h2>10. Commitment to Safety</h2>
<p>Your safety is our top priority. We adhere to all safety regulations and guidelines, including regular vehicle sanitization and health checks, to ensure a safe and secure travel experience.</p>

    </div>
      
    <div className="faq-container">
    <h2>FFAQs for Shiv Travels66 regarding the Innova Crysta Cab?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Innova Crysta Cab: - </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
