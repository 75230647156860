import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function InnovaCrystaOnRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I had an excellent experience renting an Innova from Shiv Travels66 for my family trip to Mahabaleshwar. The vehicle was in pristine condition, spacious, and comfortable. Our driver was very professional and made our journey enjoyable. I highly recommend Shiv Travels66 for anyone looking for a reliable cab service in Pune!",
    name: "Mr. Rahul Deshmukh"
  },
  {
    quote:"Shiv Travels66 made my business trip seamless with their Innova rental service. The booking process was straightforward, and the car was well-maintained. Our driver was punctual and knowledgeable about the area. I will definitely use their services again for my future trips!",
    name: "Mrs. Sneha Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            question: "What types of Innova vehicles are available for rent?",
            answer: "We offer both the standard Innova and the Innova Crysta models, equipped with air conditioning and spacious interiors to ensure comfort during your travels.",
          },
          {
            question: "How do I book an Innova on rent with Shiv Travels66?",
            answer: "You can book an Innova through our website, mobile app, or by contacting our customer service team directly via phone or email.",
          },
          {
            question: "What are the rental rates for an Innova?",
            answer: "Our rental rates vary based on the model, duration of rental, and travel distance. Please contact us for a personalized quote based on your specific requirements.",
          },
          {
            question: "Is there a minimum rental period for the Innova?",
            answer: "Yes, we typically have a minimum rental period of 6 hours. For outstation trips, the minimum duration may vary based on the distance.",
          },
          {
            question: "Are the drivers provided with the rental vehicle?",
            answer: "Yes, all our Innova rentals come with an experienced and professional driver who is knowledgeable about local routes and ensures a safe and comfortable journey.",
          },
          {
            question: "Can I rent an Innova for outstation trips?",
            answer: "Absolutely! Our Innova vehicles are perfect for outstation travel, and we offer flexible packages for both short and long-distance trips.",
          },
          {
            question: "Is fuel included in the rental price?",
            answer: "Fuel costs are typically not included in the rental price and will be charged based on actual usage. However, we offer all-inclusive packages that cover fuel and driver charges for your convenience.",
          },
          {
            question: "What is your cancellation policy?",
            answer: "We have a flexible cancellation policy. You can cancel your booking up to 24 hours before the scheduled pickup time for a full refund. Please check our terms and conditions for more details.",
          },
          {
            question: "Do you provide Innova rentals for special occasions like weddings or corporate events?",
            answer: "Yes, we offer specialized packages for weddings, corporate events, and other special occasions. Our team can assist you in arranging the perfect vehicle for your needs.",
          },
          {
            question: "What should I do if I encounter any issues during my rental?",
            answer: "Our customer support team is available 24/7. If you experience any issues during your rental, please contact us immediately, and we will address your concerns promptly.",
          },
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const vehicles = [
      {
        type: ' Force Urbania ',
        capacity: '13-Seater/ 17-Seater',
        rates: 'Rs.10,800',
        perDay: 'Rs.36/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      },
      {
        type: 'Tempo Traveller AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.7,800',
        perDay: 'Rs.26/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      }, 
      {
        type: 'Tempo Traveller Non-AC',
        capacity: '13-Seater, 17-Seater',
        rates: 'Rs.6,900',
        perDay: 'Rs.23/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      }, 
      {
        type: 'Innova Crysta',
        capacity: '6+1 Seater',
        rates: 'Rs.5,700',
        perDay: '19/ Per Km',
        driverDA: 'Rs.400',
        booking: 'Available',
        path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
      },
    ];
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
  



   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Innova On Rent in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Innova-On-Rent-in-Pune.jpg" alt="Innova on rent in Pune" />
              </div>

              <div>
              <h1>Innova On Rent in Pune with Shiv Travels66</h1>
<p>Look no further than Shiv Travels66 for your Innova on rent in Pune. Our premium Innova Crysta models are perfect for both short trips and long journeys, providing the ideal blend of luxury, comfort, and convenience.</p>

<h2>Innova Crysta on Rent in Pune</h2>
<p>Shiv Travels66 offers Innova Crysta on rent in Pune, perfect for family outings, business trips, and more. Our premium Innova models provide the ideal blend of luxury and functionality, ensuring a pleasant travel experience.</p>
<p>The Innova Crysta is well-known for its spacious interiors and advanced safety features. With a capacity to comfortably accommodate up to 7 passengers, it's perfect for group travels. Equipped with modern amenities, your journey will be smooth and enjoyable.</p>

<h2>Innova Car Rental Pune</h2>
<p>When you need a reliable vehicle for your travels, consider our Innova car rental in Pune. We offer flexible rental options tailored to your needs. Whether you require the vehicle for a few hours or several days, we have plans that suit every itinerary.</p>

<h2>Innova Crysta Rental Pune</h2>
<p>Our Innova Crysta rental in Pune provides an opportunity to explore the city and its surroundings in comfort. With professional drivers who know the local area well, you can sit back and relax while we take care of the journey.</p>

<h2>7-Seater Innova on Rent in Pune</h2>
<p>The 7-seater Innova is ideal for families or small groups. It offers ample legroom and luggage space, making it a great choice for longer trips or special occasions.</p>

<h2>Hire Innova in Pune</h2>
<p>Hiring an Innova in Pune is now easier than ever. With Shiv Travels66, you can enjoy a seamless booking process, competitive rates, and well-maintained vehicles.</p>

<h2>Innova AC Rate Per KM in Pune</h2>
<p>We offer competitive pricing for our Innova AC rate per km in Pune. This means you only pay for the distance you travel, making it an economical choice for your journeys.</p>

<h2>Innova Booking Pune</h2>
<p>To book your Innova, simply visit our website or contact our customer service team. Our Innova booking in Pune process is user-friendly and quick, allowing you to secure your vehicle effortlessly.</p>

<h2>Innova Cab Booking in Pune</h2>
<p>Our Innova cab booking in Pune service provides flexibility and convenience. Whether you need a cab for a day trip, airport transfer, or a multi-day rental, we’ve got you covered.</p>

<h2>Innova Cab in Pune</h2>
<p>Experience the best of Pune with our Innova cab in Pune services. Our professional drivers are committed to providing you with a safe and enjoyable ride, whether you're exploring the city or heading out on a longer journey.</p>

<h2>Innova Cab Service in Pune</h2>
<p>Shiv Travels66 takes pride in offering a reliable Innova cab service in Pune. Our fleet is well-maintained, and we ensure that our vehicles are equipped with all necessary amenities to enhance your travel experience.</p>

<h2>Innova Car Hire in Pune</h2>
<p>Looking for Innova car hire in Pune? Look no further! Our rental services are designed to cater to various travel needs, from corporate events to family vacations.</p>

<h2>Innova Car on Rent in Pune</h2>
<p>If you’re looking for a reliable and spacious vehicle for your travels, Shiv Travels66 offers an excellent Innova car on rent in Pune. Whether it’s for a family trip, business meeting, or any special occasion, the Innova is the perfect choice, providing comfort and style throughout your journey.</p>

<h2>Innova Car Rental Per KM in Pune</h2>
<p>We understand that each travel plan is unique. That’s why we offer flexible Innova car rental per km in Pune. Our competitive rates allow you to pay only for the distance you travel, making it a cost-effective option for both short and long trips. Enjoy the luxury of a premium vehicle without breaking the bank.</p>

<h2>Innova Crysta on Rent Pune</h2>
<p>The Innova Crysta on rent in Pune is a popular choice among travelers. Known for its spacious interiors and advanced features, the Crysta ensures a comfortable ride for families and groups. With ample legroom and luggage space, it’s designed for longer journeys, making it the ideal vehicle for sightseeing or weekend getaways.</p>

<h2>Innova Crysta Rent in Pune</h2>
<p>When you opt for Innova Crysta rent in Pune, you can expect top-notch service from Shiv Travels66. Our well-maintained vehicles are regularly serviced to ensure a smooth and safe driving experience. Book your Innova Crysta today and enjoy a hassle-free journey.</p>

<h2>Innova Rent Per KM Pune</h2>
<p>Our Innova rent per km Pune service offers transparency in pricing, allowing you to budget your travel expenses effectively. With competitive rates and no hidden charges, you can focus on enjoying your trip while we take care of your transportation needs.</p>

<h2>Innova Taxi in Pune</h2>
<p>If you need a taxi service, look no further than our Innova taxi in Pune. Whether it’s an airport transfer, local sightseeing, or an outstation trip, our professional drivers are here to assist you every step of the way. Experience the convenience of booking an Innova taxi with just a few clicks.</p>

<h2>Pune Innova Rental</h2>
<p>Our Pune Innova rental service caters to a wide range of travel requirements. We pride ourselves on our customer-centric approach, ensuring that you have a seamless booking experience and an enjoyable journey.</p>

<h2>Innova Cab on Rent in Pimpri Chinchwad</h2>
<p>For those in the Pimpri Chinchwad area, we also provide Innova cab on rent in Pimpri Chinchwad. Enjoy the same level of service and comfort with our local cab rental options. Our drivers are familiar with the region, ensuring efficient routes and timely arrivals.</p>

              </div>



              <div>
      <h1>Innova On Rent in Pune with Shiv Travels66 Contact Number</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Innova crysta on rent in Pune</p>
<p>- Innova car rental Pune</p>
<p>- Innova crysta rental Pune</p>
<p>- 7 seater innova on rent in Pune</p>
<p>- Hire innova in Pune</p>
<p>- Innova AC rate per km in Pune</p>
<p>- Innova booking Pune</p>
<p>- Innova cab booking in Pune</p>
<p>- Innova cab in Pune</p>
<p>- Innova cab service in Pune</p>


          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Innova car hire in Pune</p>
<p>- Innova car on rent in Pune</p>
<p>- Innova car rental per km in Pune</p>
<p>- Innova crysta on rent Pune</p>
<p>- Innova crysta rent in Pune</p>
<p>- Innova rent per km Pune</p>
<p>- Innova taxi in Pune</p>
<p>- Pune innova rental</p>
<p>- Innova cab on rent in Pimpri Chinchwad</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Innova On Rent in Pune with Shiv Travels66?</h2>

<p><strong>Quality Fleet:</strong> At Shiv Travels66, we offer a well-maintained fleet of Innova cars, ensuring you travel in comfort and style. Our vehicles undergo regular inspections and maintenance to provide a smooth and safe driving experience.</p>

<p><strong>Competitive Pricing:</strong> We provide competitive rates for Innova on rent in Pune, with transparent pricing and no hidden charges. Our flexible rental options allow you to choose packages that suit your budget and travel needs.</p>

<p><strong>Experienced Drivers:</strong> Our professional drivers are not only experienced but also well-versed in local routes. They prioritize your safety and comfort, ensuring a pleasant journey from start to finish.</p>

<p><strong>Convenient Booking Process:</strong> Booking an Innova with us is quick and easy. You can reserve your vehicle online, by phone, or through our user-friendly app, making the entire process hassle-free.</p>

<p><strong>Customer-Centric Service:</strong> We pride ourselves on our exceptional customer service. Our team is available 24/7 to assist you with any queries or special requests, ensuring a personalized experience.</p>

<p><strong>Flexible Rental Duration:</strong> Whether you need the Innova for a few hours, a full day, or for an extended period, we offer flexible rental durations to meet your specific needs.</p>

<p><strong>Comfort for Groups:</strong> The Innova is known for its spacious interiors, making it an ideal choice for family trips, corporate outings, or any group travel. Everyone will travel comfortably with ample legroom and luggage space.</p>

<p><strong>Safety First:</strong> Your safety is our priority. We adhere to strict safety protocols and ensure that our vehicles are equipped with the necessary safety features for your peace of mind.</p>

<p><strong>Variety of Services:</strong> Beyond regular rentals, we offer services for airport transfers, outstation trips, and local sightseeing, making us a one-stop solution for all your travel needs.</p>

<p><strong>Positive Reviews:</strong> Our satisfied customers are a testament to our service quality. We have numerous positive testimonials highlighting our commitment to excellence, reliability, and customer satisfaction.</p>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Innova On Rent in Pune with shivtravels66 ?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 " Innova On Rent in Pune " : - </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
