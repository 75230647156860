import React from 'react';
import './App.css';

function Video() {
  return (
    <div className="App">
      <h1>YouTube Video Section</h1>
      <div className="youtube-section">
        <iframe
          width="560"
          height="315"
          src="https://youtube.com/shorts/hJyf_xsc_BU?si=htByA0EUEF-hu2ph"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
}

export default Video;
