import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PunetoUjjain() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "We recently hired an Urbania from Shiv Travels66 for our family pilgrimage to Ujjain, and it was a fantastic experience! The vehicle was spacious and comfortable, perfect for our group of 15. Our driver was not only punctual but also very knowledgeable about the best routes and stops along the way. The journey was smooth, and we felt well taken care of. I highly recommend Shiv Travels66 for anyone looking to travel to Ujjain!",
    name: "Mrs.Priya K"
  },
  {
    quote:"Our company organized a retreat to Ujjain, and we decided to rent an Urbania from Shiv Travels66. The service was exceptional! The bus was clean, well-maintained, and equipped with all the amenities we needed. Our driver was professional and made sure we reached our destination on time. The whole experience was seamless, from booking to the return trip. I will definitely choose Shiv Travels66 for our future travel needs!",
    name: "Mr.Rohit Patil."
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is the capacity of the Urbania vehicle for Pune to Ujjain rental?",
            "answer": "The Force Urbania can comfortably accommodate up to 17 passengers, making it ideal for group travel."
        },
        {
            "question": "How do I book an Urbania for my trip from Pune to Ujjain?",
            "answer": "You can book your Urbania by calling us at +91 9764768866 or visiting our website. Our team will guide you through the booking process and help customize your trip."
        },
        {
            "question": "What amenities are included in the Urbania rental?",
            "answer": "Our Urbania vehicles are equipped with air conditioning, comfortable seating, entertainment systems, and charging points to ensure a pleasant journey."
        },
        {
            "question": "Can I hire the Urbania for a one-way trip to Ujjain?",
            "answer": "Yes, we offer both one-way and round-trip options for your convenience. Just let us know your travel preferences when booking."
        },
        {
            "question": "Are drivers provided with the Urbania rental?",
            "answer": "Absolutely! All our rentals come with experienced drivers who know the best routes and prioritize your safety and comfort."
        },
        {
            "question": "What is the cost of renting an Urbania for the Pune to Ujjain trip?",
            "answer": "The cost varies based on factors like duration, distance, and any additional services required. Contact us for a detailed quote tailored to your needs."
        },
        {
            "question": "What is your cancellation policy?",
            "answer": "We have a flexible cancellation policy. If you need to cancel, please inform us at least 24 hours in advance for a full refund, depending on the specific terms of your booking."
        },
        {
            "question": "Can I customize my travel itinerary?",
            "answer": "Yes! We offer customizable travel packages. Let us know your preferences for stops, sightseeing, and accommodation, and we’ll create a tailored itinerary for you."
        },
        {
            "question": "Is it safe to travel with Shiv Travels66?",
            "answer": "Yes, your safety is our priority. Our vehicles undergo regular maintenance, and our drivers adhere to all safety protocols, ensuring a secure travel experience."
        },
        {
            "question": "Do you provide travel packages that include sightseeing in Ujjain?",
            "answer": "Yes, we offer travel packages that include transportation and sightseeing options in Ujjain, allowing you to explore key attractions during your visit."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  

   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>


     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Ujjain Urbania</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page ">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune to ujjain.jpg" alt="Pune to ujjain urbania" />
              </div>

             <div>
             <h1>Pune to Ujjain Urbania on Rent with Shiv Travels66</h1>
<p>Look no further! Shiv Travels66 offers exceptional Urbania rentals tailored for your journey. Our comfortable and spacious vehicles ensure a smooth ride as you explore the sacred city of Ujjain.</p>

<h2>Urbania Mini Bus Hire from Pune to Ujjain</h2>
<p>Our Urbania mini bus hire from Pune to Ujjain is the perfect solution for families, friends, or corporate groups. The Force Urbania is spacious, accommodating up to 17 passengers comfortably, making it ideal for group outings, pilgrimages, or vacations.</p>

<h3>Benefits of Hiring an Urbania Mini Bus:</h3>
<ul>
    <li>Comfortable Seating: Enjoy generous legroom and wide seats for a relaxing ride.</li>
    <li>Modern Amenities: Equipped with air conditioning, entertainment systems, and charging ports for a pleasant journey.</li>
    <li>Experienced Drivers: Our professional drivers ensure a safe and efficient travel experience.</li>
</ul>

<h2>Pune Airport to Ujjain Urbania Vehicle on Rent</h2>
<p>If you're arriving at Pune Airport and need transportation to Ujjain, our Pune Airport to Ujjain Urbania vehicle on rent service is here for you. We provide timely pickups and drop-offs, ensuring a hassle-free start to your journey. Our drivers are familiar with the best routes, making your trip quick and convenient.</p>

<h2>Pune to Ujjain Luxury Urbania Bus Rental</h2>
<p>For those seeking a touch of luxury, our Pune to Ujjain luxury Urbania bus rental is the perfect choice. With plush interiors and premium features, you can travel in style while enjoying the scenic views along the way. This option is ideal for special occasions, corporate events, or family celebrations.</p>

<h3>Key Features of Our Luxury Urbania Rentals:</h3>
<ul>
    <li>High-Quality Interiors: Enjoy plush seating and elegant designs for a premium travel experience.</li>
    <li>Enhanced Comfort: Additional legroom and amenities to make your journey more enjoyable.</li>
    <li>Professional Service: Our team ensures all your needs are met for a luxurious travel experience.</li>
</ul>

<h2>Rent Urbania for Pune to Ujjain Travel with Shiv Travels66</h2>
<p>Renting an Urbania for your Pune to Ujjain travel with Shiv Travels66 is simple and straightforward. Here’s how you can book:</p>
<ol>
    <li>Contact Us: Call us at +91 9764768866 or visit our website to check availability.</li>
    <li>Select Your Vehicle: Choose the Urbania that suits your group size and travel needs.</li>
    <li>Customize Your Itinerary: Let us know your travel plans, and we’ll create a tailored package just for you.</li>
    <li>Confirm Your Booking: Once all details are finalized, we’ll secure your reservation.</li>
</ol>

<h2>Affordable Urbania Rental for Pune to Ujjain</h2>
<p>We offer affordable Urbania rental for Pune to Ujjain, catering to travelers on a budget without compromising on comfort. Our transparent pricing and flexible packages make it easy to find the perfect solution for your travel needs.</p>

<h3>Why Choose Our Affordable Urbania Rentals?</h3>
<ul>
    <li>Competitive Rates: Enjoy great value for your money with our budget-friendly pricing.</li>
    <li>Comfort and Space: Our Urbania buses provide ample space for passengers and luggage.</li>
    <li>Dependable Service: Count on us for timely pickups and excellent customer service.</li>
</ul>

<h2>Pune to Ujjain Urbania Transport Services</h2>
<p>Our Pune to Ujjain Urbania transport services are designed for both group travel and individual needs. Whether you're heading for a family trip, a pilgrimage, or a corporate outing, our reliable transport options ensure you arrive at your destination safely and comfortably.</p>

<h3>Key Features of Our Transport Services:</h3>
<ul>
    <li>Experienced Drivers: Our professional drivers are well-versed in the best routes to Ujjain, ensuring a smooth journey.</li>
    <li>Punctuality: We value your time and guarantee timely departures and arrivals.</li>
    <li>Flexible Scheduling: Adjust your itinerary to suit your travel plans.</li>
</ul>

<h2>Explore Ujjain with Urbania from Pune</h2>
<p>Experience the beauty and spirituality of Ujjain by renting an Urbania from Pune. With spacious seating and modern amenities, our Urbania buses are perfect for exploring Ujjain's famous attractions, including the Mahakaleshwar Temple, the Kshipra River, and the historical landmarks that define this sacred city.</p>

<h2>Pune to Ujjain Urbania Travel Packages</h2>
<p>Our Pune to Ujjain Urbania travel packages are tailored to meet the needs of every traveler. Whether you want a short getaway or an extended trip, we can customize a package that includes transportation, accommodation, and guided tours of Ujjain.</p>

<h3>Sample Package Inclusions:</h3>
<ul>
    <li>Round Trip Transportation: Comfortable travel in our Urbania buses.</li>
    <li>Sightseeing Tours: Visits to key attractions in Ujjain.</li>
    <li>Accommodation Options: Recommendations or bookings at nearby hotels.</li>
</ul>

<h2>Pune to Ujjain Urbania Road Trip on Rent</h2>
<p>Embark on a thrilling Pune to Ujjain Urbania road trip on rent! Enjoy the freedom to explore at your own pace. Our Urbania buses are perfect for creating memorable road trip experiences with family and friends. Stop along the way to enjoy local cuisine and attractions!</p>

<h2>Luxury Travel Pune to Ujjain Urbania Bus</h2>
<p>For those seeking an elevated experience, we offer luxury travel from Pune to Ujjain Urbania bus rentals. Enjoy premium features and services that enhance your travel experience, making every moment special.</p>

<h3>Features of Our Luxury Urbania Buses:</h3>
<ul>
    <li>High-End Interiors: Luxurious seating and design for maximum comfort.</li>
    <li>Exclusive Amenities: Enjoy entertainment systems, refreshments, and more.</li>
    <li>Personalized Service: Our attentive drivers ensure all your needs are met.</li>
</ul>


             </div>




              <div>
      <h1>Pune to Mumbai Ujjain with Shiv Travels66 Contact Number: </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Ujjain Urbania Vehicle Rental</p>
<p>- Urbania Mini Bus Hire from Pune to Ujjain</p>
<p>- Pune Airport to Ujjain Urbania Vehicle on Rent</p>
<p>- Pune to Ujjain Luxury Urbania Bus Rental</p>
<p>- Rent Urbania for Pune to Ujjain Travel</p>
<p>- Pune to Ujjain Urbania Bus with Driver</p>

          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Affordable Urbania Rental for Pune to Ujjain</p>
<p>- Pune to Ujjain Urbania Transport Services</p>
<p>- Explore Ujjain with Urbania from Pune</p>
<p>- Pune to Ujjain Urbania Travel Packages</p>
<p>- Pune to Ujjain Urbania Road Trip on Rent</p>
<p>- Luxury Travel Pune to Ujjain Urbania Bus</p>

          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Shiv Travels66 for Pune to Ujjain Urbania On Rent?</h2>
<p>When planning your journey from Pune to Ujjain, choosing the right travel partner is essential for a comfortable and memorable experience. Here are several compelling reasons to select Shiv Travels66 for your Pune to Ujjain Urbania on rent needs:</p>

<ol>
    <li>
        <strong>Comfort and Space:</strong>
        <p>Our Urbania vehicles are designed for comfort, featuring spacious seating that accommodates up to 17 passengers. With ample legroom and storage for luggage, everyone can travel comfortably, making it ideal for family trips, group outings, or pilgrimages.</p>
    </li>
    <li>
        <strong>Affordable Pricing:</strong>
        <p>At Shiv Travels66, we offer competitive pricing without compromising on quality. Our affordable Urbania rentals are tailored to suit various budgets, ensuring that you get the best value for your money.</p>
    </li>
    <li>
        <strong>Experienced Drivers:</strong>
        <p>Our professional drivers are well-trained and familiar with the best routes to Ujjain. They prioritize safety and punctuality, ensuring a smooth journey while you sit back and enjoy the ride.</p>
    </li>
    <li>
        <strong>Customizable Travel Packages:</strong>
        <p>We understand that every trip is unique. That's why we offer customizable travel packages for your Pune to Ujjain journey. Whether you need specific stops, sightseeing options, or extended travel dates, we can tailor a package that fits your requirements.</p>
    </li>
    <li>
        <strong>Modern Amenities:</strong>
        <p>Traveling in our Urbania buses means you have access to modern amenities such as air conditioning, entertainment systems, and charging points. We strive to make your travel experience as enjoyable as possible.</p>
    </li>
    <li>
        <strong>Punctual and Reliable Service:</strong>
        <p>We value your time and aim for timely pickups and drop-offs. Our commitment to reliability ensures that your travel plans are executed seamlessly, allowing you to focus on enjoying your trip.</p>
    </li>
    <li>
        <strong>Excellent Customer Support:</strong>
        <p>Our customer support team is available to assist you at every stage of your journey, from booking to the end of your trip. We’re here to answer any questions and ensure your experience with Shiv Travels66 is positive and hassle-free.</p>
    </li>
    <li>
        <strong>Safety First:</strong>
        <p>Your safety is our priority. Our Urbania vehicles undergo regular maintenance and safety checks, and our drivers adhere to all safety protocols. You can travel with peace of mind knowing you’re in good hands.</p>
    </li>
    <li>
        <strong>Local Expertise:</strong>
        <p>Our team is knowledgeable about Ujjain's attractions, offering recommendations for must-visit sites and local experiences. We can help enhance your trip by suggesting the best places to explore during your stay.</p>
    </li>
    <li>
        <strong>Positive Customer Reviews:</strong>
        <p>Our satisfied customers speak for our service quality. With numerous positive testimonials highlighting our reliability and comfort, you can trust Shiv Travels66 to make your journey memorable.</p>
    </li>
</ol>


    </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 regarding Pune to Ujjain with Urbania services ?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Pune to Ujjain urbania service:</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
           
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
