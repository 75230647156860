import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function TempoTravellerHireForWeddingInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"We hired a Tempo Traveller from Shiv Travels66 for our wedding in Pune, and we couldn’t be happier with our choice! The vehicle was spacious, clean, and well-maintained, accommodating our large family comfortably. Our driver was professional and punctual, ensuring that everyone arrived at the venue on time. The entire experience was hassle-free, and I highly recommend Shiv Travels66 for anyone looking for reliable wedding transportation!",
    name: "Mrs.Priya Deshmukh"
  },
  {
    quote: "I cannot thank Shiv Travels66 enough for their outstanding service during my sister's wedding. We booked a luxury Tempo Traveller for our guests, and it exceeded our expectations. The comfort and amenities provided made the journey enjoyable for everyone. The driver was courteous and knowledgeable, making sure our guests felt special. If you’re planning a wedding in Pune, I highly recommend Shiv Travels66 for your transportation needs!",
    name: "Mr. Rohit Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            question: "What are the available seating options for Urbania on rent in Pune?",
            answer: "Shiv Travels66 offers Urbania vehicles with 10-seater, 13-seater, and 17-seater options, making them ideal for small to medium-sized groups."
          },
          {
            question: "How can I book an Urbania on rent in Pune?",
            answer: "You can easily book an Urbania by calling us at [Your Contact Number], visiting our website, or sending us an inquiry through our contact form. We’ll confirm your booking and provide all the necessary details."
          },
          {
            question: "Is there an option for AC in the Urbania vehicles?",
            answer: "Yes, all our Urbania vehicles come equipped with air conditioning to ensure a comfortable ride for you and your guests."
          },
          {
            question: "What are the payment options available for booking an Urbania?",
            answer: "We accept various payment methods, including bank transfers and cash. Detailed payment options will be provided upon confirming your booking."
          },
          {
            question: "Can I customize the rental package for my needs?",
            answer: "Absolutely! We offer flexible rental packages that can be tailored to your specific requirements, including transportation for events, airport pickups, and more."
          },
          {
            question: "Do you provide a driver with the Urbania?",
            answer: "Yes, every Urbania rental includes a professional and experienced driver who is familiar with the local area and traffic conditions."
          },
          {
            question: "What if I need to cancel my booking?",
            answer: "We have a cancellation policy in place. Please contact us for specific details regarding cancellations and any applicable fees."
          },
          {
            question: "How far in advance should I book an Urbania?",
            answer: "We recommend booking your Urbania at least a few weeks in advance, especially during peak seasons, to ensure availability."
          },
          {
            question: "Are there any additional charges?",
            answer: "All charges will be discussed upfront during the booking process. We strive for transparency and ensure there are no hidden fees."
          },
          {
            question: "How can I get a quote for hiring an Urbania?",
            answer: "You can get a quote by contacting us via phone or through our website. Provide us with your requirements, and we’ll send you a customized quote."
          }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  
   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];


   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home| Urbania Hire for Corporate Events in Pune | Tempo Traveller On Rent in Pune
                 | Pune to Mumbai Urbania on Rent| Pune to Ujjain Urbania On Rent</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="  Urbania Hire for Corporate Events in Pune, Tempo Traveller On Rent in Pune,Pune to Mumbai Urbania on Rent
                Pune to Ujjain Urbania On Rent" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Tempo Traveller Hire for Wedding in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Tempo-Traveller Hire-for-Wedding in-Pune.jpg" alt="Tempo traveller hire for wedding" />
              </div>

              <div>
              <h1>Tempo Traveller Hire for Wedding in Pune with Shiv Travels66</h1>

<p>Weddings are grand celebrations filled with joy, love, and togetherness, and every detail matters—especially transportation. At Shiv Travels66, we understand the importance of seamless travel during your special day. Our Tempo Traveller hire for weddings in Pune provides the perfect solution for transporting guests comfortably and stylishly.</p>

<h2>17 Seater Tempo Traveller on Rent in Pune</h2>
<p>At Shiv Travels66, we offer spacious and comfortable vehicles perfect for group outings, family trips, or corporate events. Our 17-seater Tempo Travellers are well-equipped with modern amenities to ensure a pleasant journey for all passengers. Whether you're heading for a weekend getaway or a local sightseeing tour, you can rely on us for a safe and enjoyable ride.</p>

<h2>Hire Mini Bus for Trip in Pune</h2>
<p>You can hire a mini bus for your trip in Pune with Shiv Travels66. Our mini buses provide ample space and comfort, making them ideal for family vacations, corporate retreats, or school outings. With experienced drivers and well-maintained vehicles, we guarantee a smooth and enjoyable travel experience for your entire group.</p>

<h2>Mini Bus on Rent in Pune for Marriage Function</h2>
<p>Make your wedding celebrations hassle-free by opting for a mini bus on rent in Pune for marriage functions. Our spacious mini buses can accommodate your family and friends, ensuring everyone arrives at the venue together. With luxurious seating and air conditioning, your guests will travel in comfort and style. Trust Shiv Travels66 to provide reliable transportation for your special day.</p>

<h2>Mini Bus on Rent in Pune for Wedding Function</h2>
<p>If you're looking for a mini bus on rent in Pune for wedding functions, we've got you covered. Our well-maintained vehicles are perfect for shuttling guests between venues, including the ceremony and reception. With our punctual drivers and spacious seating, we ensure your guests enjoy a comfortable journey, allowing you to focus on making lasting memories.</p>

<h2>Tempo Traveller on Rent in Pune Rate Per Km</h2>
<p>At Shiv Travels66, we offer competitive pricing with transparent rates based on your travel needs. Whether you’re planning a day trip or an extended journey, we provide affordable options tailored to your budget. Contact us for a detailed quote and experience excellent value for your transportation.</p>

<h2>14 Seater Tempo Traveller on Rent in Pune</h2>
<p>For smaller groups, our 14-seater Tempo Traveller on rent in Pune is an excellent choice. Ideal for family trips, corporate outings, or short vacations, our 14-seater vehicles offer ample space and comfort. Equipped with air conditioning and modern amenities, your group will enjoy a pleasant journey. Book with Shiv Travels66 for a reliable and enjoyable travel experience.</p>

<h2>Tempo Traveller on Rent in Pune for Corporate</h2>
<p>Our Tempo Traveller on rent in Pune for corporate travel is perfect for team outings, conferences, or client meetings. We provide spacious and comfortable vehicles to ensure your team arrives on time and in style. With professional drivers and excellent customer service, Shiv Travels66 is your trusted partner for corporate transportation needs.</p>

<h2>Tempo Traveller on Rent in Pune for Events</h2>
<p>If you’re looking for a tempo traveller on rent in Pune for events, Shiv Travels66 has got you covered. Whether it’s a family gathering, corporate event, or a special celebration, our spacious Tempo Travellers provide the perfect transportation solution. With comfortable seating and ample space for luggage, you can enjoy a stress-free journey to any event. Our experienced drivers ensure punctuality and a smooth ride, allowing you to focus on making memories.</p>

<h2>Luxury Tempo Traveller on Rent in Pune</h2>
<p>For those who want to travel in style, our luxury tempo traveller on rent in Pune is an excellent choice. Our luxury vehicles come equipped with premium amenities, including plush seating, air conditioning, and entertainment systems. Ideal for weddings, anniversaries, or corporate events, our luxury Tempo Travellers provide an upscale travel experience. Treat your guests to comfort and elegance while traveling to your destination.</p>

<h2>Tempo Traveller on Rent in Pune Monthly Basis</h2>
<p>We offer tempo traveller on rent in Pune on a monthly basis. This service is perfect for businesses needing transport for their employees or families planning long trips. With flexible rental terms and competitive pricing, you can enjoy the convenience of having a reliable vehicle at your disposal. Contact Shiv Travels66 for tailored solutions that meet your monthly travel needs.</p>

<h2>AC Tempo Traveller on Rent in Pune</h2>
<p>Stay cool and comfortable during your travels with our AC tempo traveller on rent in Pune. Our air-conditioned Tempo Travellers are perfect for long journeys, ensuring a pleasant climate regardless of the weather outside. Whether you're heading for a day trip or an extended outing, our AC vehicles provide a refreshing environment for all passengers. Experience a comfortable ride with Shiv Travels66.</p>

<h2>Pune to Mahabaleshwar Tempo Traveller on Rent</h2>
<p>Our Pune to Mahabaleshwar tempo traveller on rent service makes your travel hassle-free and enjoyable. With scenic views along the way, our experienced drivers will ensure you reach your destination safely and on time. Enjoy the convenience of door-to-door service, and let us handle the logistics while you focus on your holiday.</p>

<h2>Pune to Goa Tempo Traveller</h2>
<p>Take a memorable trip to Goa with our Pune to Goa tempo traveller service. Perfect for family vacations or group trips with friends, our Tempo Travellers provide comfortable seating and ample space for luggage. Enjoy the scenic journey while our professional drivers navigate the route, allowing you to relax and anticipate your exciting getaway. Book your Pune to Goa Tempo Traveller today and embark on an unforgettable adventure!</p>

<h2>Pune to Lonavala Tempo Traveller on Rent</h2>
<p>Escape to Lonavala with our Pune to Lonavala tempo traveller on rent service. Ideal for weekend getaways or day trips, our spacious Tempo Travellers provide a comfortable ride for your group. With air conditioning and ample luggage space, you can enjoy a relaxing journey to the hills. Experience the beauty of Lonavala while traveling in style with Shiv Travels66.</p>

              </div>




              <div>
      <h1>
Tempo Traveller Hire for Wedding in Pune with Shiv Travels66 Contact Number
</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          
          <p>- 17 Seater Tempo Traveller on rent in Pune</p>
<p>- Hire Mini Bus for trip in Pune</p>
<p>- Mini bus on rent in Pune for marriage function</p>
<p>- Mini bus on rent in Pune for Wedding Function</p>
<p>- Tempo Traveller on Rent in Pune rate per km</p>
<p>- 14 Seater Tempo Traveller on Rent in Pune</p>
<p>- Tempo traveller on rent in Pune for corporate</p>


          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Tempo traveller on rent in Pune for events</p>
<p>- Luxury tempo traveller on rent in Pune</p>
<p>- Tempo traveller on rent in Pune monthly basis</p>
<p>- AC tempo traveller on rent in Pune</p>
<p>- Pune to Mahabaleshwar tempo traveller on rent</p>
<p>- Pune to Goa tempo traveller</p>
<p>- Pune to Lonavala tempo traveller on rent</p>

          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Tempo Traveller Hire for Wedding in Pune with Shiv Travels66?</h2>
<p>When it comes to planning a wedding, every detail matters, including transportation. Choosing Shiv Travels66 for Tempo Traveller hire for weddings in Pune offers a range of benefits that ensure your special day runs smoothly. Here’s why you should choose us:</p>

<ol>
    <li>
        <strong>Spacious and Comfortable Vehicles</strong><br/>
        Our Tempo Travellers are designed to accommodate large groups comfortably. With ample seating and space for luggage, your family and friends can travel together without any hassle. This ensures everyone arrives at the venue relaxed and ready to celebrate.
    </li>
    <li>
        <strong>Luxury and Style</strong><br/>
        At Shiv Travels66, we understand the significance of your wedding day. Our luxury Tempo Travellers come equipped with modern amenities to enhance your guests' travel experience. From comfortable seating to air conditioning, we ensure that your guests enjoy a stylish ride.
    </li>
    <li>
        <strong>Professional and Experienced Drivers</strong><br/>
        Our drivers are not only skilled and licensed but also well-versed in the best routes and local traffic patterns. They prioritize your safety and punctuality, making sure that everyone arrives at the ceremony and reception on time.
    </li>
    <li>
        <strong>Flexible Booking Options</strong><br/>
        We offer a variety of rental packages tailored to fit your wedding schedule. Whether you need transportation for the entire day, airport pickups for out-of-town guests, or shuttles between venues, we can customize our services to meet your specific needs.
    </li>
    <li>
        <strong>Affordable Pricing</strong><br/>
        Weddings can be expensive, but our Tempo Traveller hire for weddings is competitively priced with no hidden costs. We provide transparent pricing options that allow you to budget effectively for your transportation needs.
    </li>
    <li>
        <strong>Reliable and Timely Service</strong><br/>
        At Shiv Travels66, we pride ourselves on our reliability. We understand the importance of timing on your wedding day, and our team is committed to ensuring that everything runs smoothly. You can count on us to be punctual and dependable.
    </li>
    <li>
        <strong>Positive Customer Feedback</strong><br/>
        We have numerous satisfied clients who appreciate our dedication to quality service. Our testimonials reflect our commitment to excellence, making us a trusted choice for wedding transportation in Pune.
    </li>
    <li>
        <strong>Customized Itineraries</strong><br/>
        Every wedding is unique, and we are here to accommodate your specific requirements. Our team works closely with you to create customized itineraries that ensure seamless transportation for your guests, allowing you to focus on enjoying your big day.
    </li>
    <li>
        <strong>Commitment to Safety</strong><br/>
        Your safety is our top priority. All our vehicles undergo regular maintenance and safety checks, ensuring a secure journey for your guests. We adhere to all safety protocols to give you peace of mind.
    </li>
    <li>
        <strong>Customer Support</strong><br/>
        Our dedicated customer support team is available to assist you with any inquiries or concerns throughout the booking process. We aim to provide a hassle-free experience from start to finish.
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Tempo Traveller Hire for Wedding in Pune  with Shiv Travels66</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4> Testimonials for Shiv Travels66 regarding Tempo Traveller Hire for Weddings in Pune:</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
