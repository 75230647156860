import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PuneToShirdiInnovaCrystaCab() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"I recently booked an Innova Crysta cab with Shiv Travels66 for my trip from Pune to Shirdi, and I couldn’t be happier with the service! The vehicle was clean and comfortable, making the journey enjoyable. Our driver was professional and knew the best routes, ensuring we arrived on time. I highly recommend Shiv Travels66 for anyone looking for reliable and comfortable travel!",
    name: "Mrs.Anjali Deshmukh"
  },
  {
    quote: "Shiv Travels66 made our pilgrimage to Shirdi hassle-free! The Innova Crysta was spacious enough for our family, and the ride was smooth. Our driver was not only punctual but also very friendly and helpful throughout the journey. The booking process was straightforward, and the rates were reasonable. I will definitely choose Shiv Travels66 for our future trips!",

    name: "Mr. Rohit Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What makes Shiv Travels66 the best choice for Pune to Shirdi Innova Crysta cab service?",
            "answer": "Shiv Travels66 offers comfortable rides with spacious seating, reliable service, transparent pricing, experienced drivers, an easy booking process, flexible packages, well-maintained vehicles, 24/7 customer support, a focus on customer satisfaction, and safe travel practices."
        },
        {
            "question": "What is the cost of booking an Innova Crysta cab from Pune to Shirdi?",
            "answer": "The cost varies based on factors like distance, duration, and specific package chosen. We offer competitive pricing with no hidden charges. For a detailed quote, please contact us."
        },
        {
            "question": "How long does the journey from Pune to Shirdi take?",
            "answer": "The drive from Pune to Shirdi typically takes around 4 to 5 hours, depending on traffic and road conditions."
        },
        {
            "question": "Can I book the Innova Crysta cab for a round trip?",
            "answer": "Yes, we offer round trip packages. You can book the Innova Crysta cab for both your journey to Shirdi and back to Pune at your convenience."
        },
        {
            "question": "Are the cabs well-maintained and clean?",
            "answer": "Absolutely! Our Innova Crysta cabs are regularly serviced and thoroughly cleaned before each trip to ensure maximum comfort and safety for our passengers."
        },
        {
            "question": "Is the driver experienced and knowledgeable about the route?",
            "answer": "Yes, our drivers are highly experienced and familiar with the best routes to Shirdi. They prioritize your safety and comfort during the journey."
        },
        {
            "question": "Do you offer one-way cab bookings from Pune to Shirdi?",
            "answer": "Yes, we provide one-way cab bookings from Pune to Shirdi. Please let us know your travel plans, and we’ll accommodate your needs."
        },
        {
            "question": "What payment methods do you accept for cab bookings?",
            "answer": "We accept various payment methods, including cash, credit/debit cards, and digital wallets. Please confirm your preferred payment option at the time of booking."
        },
        {
            "question": "Can I cancel or modify my booking?",
            "answer": "Yes, you can cancel or modify your booking. We recommend contacting our customer support team as soon as possible to discuss your options."
        },
        {
            "question": "Is there a limit on the number of passengers for the Innova Crysta cab?",
            "answer": "The Innova Crysta comfortably accommodates up to 6 to 7 passengers. For larger groups, we recommend booking multiple vehicles or opting for our mini-bus services."
        },
        {
            "question": "Do you provide additional amenities in the cab?",
            "answer": "Yes, our Innova Crysta cabs are equipped with essential amenities for a comfortable journey, including air conditioning, spacious seating, and ample luggage space."
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  


   
  return (
    <>
  <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img2">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Shirdi Innova Crysta Cab</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-to-Shirdi Innova-Crysta-Cab.jpg" alt="Urbania on rent in Pune" />
              </div>

              <div>
              <h1>Pune to Shirdi Innova Crysta Cab: Your Comfortable Journey Awaits with Shiv Travels66</h1>
<p>Look no further than Shiv Travels66 for a premium travel experience with our Innova Crysta cab service. We understand that traveling to Shirdi is not just a journey; it’s a spiritual experience. Our fleet of well-maintained Innova Crysta vehicles ensures that your journey is comfortable, safe, and memorable.</p>

<h2>Your Ideal Travel Solution</h2>
<p>Our Pune to Shirdi Innova Crysta cab service offers a comfortable and reliable travel experience tailored to your needs. With spacious interiors and premium features, our Innova Crysta cabs ensure that your journey is enjoyable and stress-free.</p>

<h2>Pune to Shirdi Innova Crysta Taxi</h2>
<p>Our Pune to Shirdi Innova Crysta taxi service is perfect for families and groups looking for a comfortable ride. With seating for up to 7 passengers, the Innova Crysta provides ample legroom and luggage space, making it the ideal choice for your trip to the holy city of Shirdi.</p>

<h2>Pune to Shirdi Innova Crysta Cab Package</h2>
<p>We offer competitive Pune to Shirdi Innova Crysta cab packages designed to suit different travel needs. Whether you're planning a one-day trip or a longer stay, we can customize a package that fits your schedule and budget. Enjoy the flexibility of choosing your itinerary and making stops along the way!</p>

<h2>Pune to Shirdi Innova Crysta Cab Charges</h2>
<p>Our Pune to Shirdi Innova Crysta cab charges are transparent and competitive. We understand the importance of clear pricing, which is why we provide an upfront quote based on your travel dates and requirements. There are no hidden fees, ensuring you know exactly what to expect.</p>

<h2>Pune to Shirdi Innova Crysta Cab Fare</h2>
<p>The Pune to Shirdi Innova Crysta cab fare typically includes fuel costs, driver charges, and basic toll fees. Depending on the duration of your trip and additional stops, the fare may vary. Contact us for a personalized quote that fits your journey.</p>

<h2>Pune to Shirdi Innova Crysta Taxi Fare</h2>
<p>If you're looking for affordable travel, our Pune to Shirdi Innova Crysta taxi fare offers great value. Enjoy a comfortable ride without breaking the bank. Our competitive pricing ensures you can travel in style while keeping your budget in check.</p>

<h2>Pune to Shirdi Innova Crysta Cab One Way</h2>
<p>For those who need a Pune to Shirdi Innova Crysta cab one way, we provide flexible booking options. Whether you're heading to Shirdi for a quick visit or an extended stay, our one-way service makes it easy to reach your destination without any hassle.</p>

<h2>Pune to Shirdi Innova Crysta Cab Service</h2>
<p>Our Pune to Shirdi Innova Crysta cab service is dedicated to providing top-notch customer support and a seamless travel experience. From booking to drop-off, our team is here to assist you every step of the way, ensuring you arrive at your destination safely and comfortably.</p>

<h2>Shirdi to Pune Innova Crysta Cab</h2>
<p>Planning to return from Shirdi to Pune? Our Shirdi to Pune Innova Crysta cab service is readily available. Enjoy a hassle-free return journey with the same level of comfort and convenience that you experienced on your way to Shirdi.</p>

<h2>Pune to Shirdi Innova Crysta Taxi Drop</h2>
<p>Our Pune to Shirdi Innova Crysta taxi drop service is ideal for travelers who prefer a direct and hassle-free ride. Whether you're traveling alone or with a group, our Innova Crysta cabs offer ample space for both passengers and luggage, ensuring a comfortable journey to Shirdi.</p>

<h2>Pune to Shirdi Innova Crysta Car Rental</h2>
<p>Our Pune to Shirdi Innova Crysta car rental service allows you to rent the vehicle for a specified duration. Enjoy the freedom to explore Shirdi and its surroundings at your own pace, with the comfort of a luxury vehicle.</p>

<h2>Pune to Shirdi Innova Crysta Cab Booking</h2>
<p>Booking your Pune to Shirdi Innova Crysta cab has never been easier! With our user-friendly online booking system, you can reserve your cab in just a few clicks. Choose your travel dates, specify the number of passengers, and get instant confirmation for your journey.</p>

<h2>Cab Service from Pune to Shirdi</h2>
<p>Our dedicated cab service from Pune to Shirdi ensures that you have a reliable mode of transport for your pilgrimage. Our experienced drivers are familiar with the route and can provide recommendations for your trip, making your journey more enjoyable.</p>

<h2>Pune to Shirdi Innova Crysta Cab Booking Rates</h2>
<p>We offer competitive Pune to Shirdi Innova Crysta cab booking rates to suit every budget. Our transparent pricing model means you won't encounter hidden fees, allowing you to travel confidently. Contact us for a detailed quote based on your specific requirements.</p>

<h2>Pune to Shirdi Package by Car</h2>
<p>If you’re looking for an all-inclusive travel experience, consider our Pune to Shirdi package by car. This package often includes not just transportation but also accommodations and sightseeing options, ensuring a hassle-free pilgrimage to the holy site.</p>

<h2>Pune Shirdi Innova Crysta Taxi</h2>
<p>For a comfortable and spacious ride, choose our Pune Shirdi Innova Crysta taxi service. Perfect for families, groups, or solo travelers, our Innova Crysta cabs are well-equipped to meet your travel needs. Enjoy a smooth and relaxing ride as you make your way to Shirdi.</p>

<h2>Shirdi to Pune Airport Cab: Your Reliable Travel Partner</h2>
<p>Traveling from Shirdi to Pune Airport? Our Shirdi to Pune Airport cab service is designed for your convenience, ensuring a smooth and comfortable journey. With experienced drivers and well-maintained vehicles, you can trust us for a hassle-free travel experience.</p>

<h2>Cab for Shirdi from Pune</h2>
<p>Need a cab for Shirdi from Pune? Our dedicated service provides reliable transportation, whether you're heading to Shirdi for a pilgrimage or any other reason. Enjoy the comfort of our vehicles and the expertise of our drivers, who know the best routes to get you there on time.</p>

<h2>Pune Airport to Shirdi Taxi Fare</h2>
<p>When planning your trip, understanding the Pune Airport to Shirdi taxi fare is crucial. We offer competitive pricing with no hidden charges. Our fares are calculated based on distance, vehicle type, and travel duration, ensuring you receive a fair and transparent quote.</p>

<h2>Pune Airport to Shirdi Taxi Drop</h2>
<p>Our Pune Airport to Shirdi taxi drop service is designed for convenience. After a long flight, relax and let us handle your transportation needs. Our professional drivers will meet you at the airport and ensure you reach Shirdi safely and comfortably.</p>

<h2>Pune to Shirdi Innova Crysta Cab Cost</h2>
<p>Our Pune to Shirdi Innova Crysta cab cost is reasonable and offers excellent value for money. Ideal for families and groups, the Innova Crysta ensures you have a pleasant journey with plenty of legroom and luggage space.</p>

<h2>Pune to Shirdi Car Hire</h2>
<p>Explore Shirdi at your own pace with our Pune to Shirdi car hire service. Enjoy the flexibility of having your vehicle for the duration of your trip. Our well-maintained cars are available for rent, providing you with the freedom to travel according to your schedule.</p>

<h2>Pune to Shirdi Taxi Charges</h2>
<p>Our Pune to Shirdi taxi charges are transparent and designed to fit various budgets. We provide a detailed breakdown of charges upfront, including all relevant costs, so you can plan your trip without any surprises.</p>

<h2>Shirdi Pune Innova Crysta Cab</h2>
<p>For a luxurious travel experience, choose our Shirdi Pune Innova Crysta cab service. With premium amenities and a focus on comfort, our Innova Crysta cabs are perfect for longer journeys, ensuring you arrive at your destination refreshed.</p>

<h2>Shirdi Pune Innova Crysta Taxi</h2>
<p>Our Shirdi Pune Innova Crysta taxi service combines comfort, style, and affordability. Whether you're traveling solo or with family, our taxis cater to all your needs, making your trip to and from Shirdi enjoyable and stress-free.</p>

<h2>Taxi Service from Pune to Shirdi</h2>
<p>Experience our reliable taxi service from Pune to Shirdi for your next pilgrimage. Our team is committed to providing safe, efficient, and comfortable transportation, allowing you to focus on your journey and spiritual experience.</p>

              </div>

          


              <div>
      <h1>Pune to Shirdi Innova Crysta Cab with Shiv Travels66 Contact Number: </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Shirdi Innova Crysta cab</p>

<p>- Pune to Shirdi Innova Crysta taxi</p>

<p>- Pune to Shirdi Innova Crysta cab package</p>

<p>- Pune to Shirdi Innova Crysta cab charges</p>

<p>- Pune to Shirdi Innova Crysta cab fare</p>

<p>- Pune to Shirdi Innova Crysta taxi fare</p>
<p>- Pune to Shirdi Innova Crysta cab one way</p>

<p>- Pune to Shirdi Innova Crysta cab service</p>

<p>- Shirdi to Pune Innova Crysta cab</p>

<p>- Pune airport to Shirdi Innova Crysta cab</p>

<p>- Pune to Shirdi Innova Crysta taxi drop</p>

<p>- Pune to Shirdi Innova Crysta car rental</p>

<p>- Shirdi to Pune airport cab</p>
<p>- Shirdi Pune Innova Crysta taxi</p>




          </td>
          <td style={{ borderWidth: "0px" }}>
        
          <p>- Pune to Shirdi Innova Crysta cab booking</p>

<p>- Cab service from Pune to Shirdi</p>

<p>- Pune to Shirdi Innova Crysta cab booking rates</p>

<p>- Pune to Shirdi package by car</p>

<p>- Pune Shirdi Innova Crysta taxi</p>

<p>- Cab for Shirdi from Pune</p>

<p>- Pune airport to Shirdi taxi fare</p>

<p>- Pune airport to Shirdi taxi drop</p>

<p>- Pune to Shirdi Innova Crysta cab cost</p>

<p>- Pune to Shirdi car hire</p>

<p>- Pune to Shirdi taxi charges</p>

<p>- Shirdi Pune Innova Crysta cab</p>

<p>- Taxi service from Pune to Shirdi</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Pune to Shirdi Innova Crysta Cab with Shiv Travels66?</h2>

<p><strong>Comfortable Ride:</strong> Our Innova Crysta cabs are designed for ultimate comfort, providing spacious seating and ample legroom. Whether you’re traveling alone or with family, you’ll enjoy a relaxing journey from Pune to Shirdi.</p>

<p><strong>Reliable Service:</strong> At Shiv Travels66, we pride ourselves on punctuality and reliability. Our experienced drivers ensure you reach your destination on time, allowing you to plan your pilgrimage without any hassle.</p>

<p><strong>Transparent Pricing:</strong> We offer competitive pricing for our Pune to Shirdi Innova Crysta cab service, with no hidden charges. Our upfront pricing model helps you stay within your budget, so you know exactly what to expect.</p>

<p><strong>Experienced Drivers:</strong> Our professional drivers are knowledgeable about the best routes and local traffic conditions. They are trained to provide excellent customer service, ensuring a safe and enjoyable journey.</p>

<p><strong>Easy Booking Process:</strong> Booking your Innova Crysta cab is simple and convenient with Shiv Travels66. You can reserve your vehicle online or over the phone, with instant confirmation for your peace of mind.</p>

<p><strong>Flexible Packages:</strong> We offer customizable packages to meet your specific travel needs, whether you need a one-way trip or a round trip. You can also choose from hourly rental options for added flexibility.</p>

<p><strong>Clean and Well-Maintained Vehicles:</strong> Our fleet of Innova Crysta cabs is regularly serviced and thoroughly cleaned before every trip. We prioritize your safety and comfort, ensuring you travel in a vehicle that meets the highest standards.</p>

<p><strong>24/7 Customer Support:</strong> Our dedicated customer support team is available around the clock to assist you with any inquiries or concerns. Whether you need help with booking or have questions during your trip, we’re here for you.</p>

<p><strong>Customer Satisfaction:</strong> We value our customers and strive to exceed their expectations. Our commitment to quality service has earned us positive reviews and repeat clients, making us a trusted choice for your travel needs.</p>

<p><strong>Safe Travel Practices:</strong> Your safety is our top priority. We follow all safety guidelines and protocols to ensure a secure travel experience, giving you peace of mind as you embark on your journey to Shirdi.</p>

<h2>FAQs for Pune to Shirdi Innova Crysta Cab</h2>

<p><strong>What is the cost of booking an Innova Crysta cab from Pune to Shirdi?</strong><br/>
The cost varies based on factors like distance, duration, and specific package chosen. We offer competitive pricing with no hidden charges. For a detailed quote, please contact us.</p>

<p><strong>How long does the journey from Pune to Shirdi take?</strong><br/>
The drive from Pune to Shirdi typically takes around 4 to 5 hours, depending on traffic and road conditions.</p>

<p><strong>Can I book the Innova Crysta cab for a round trip?</strong><br/>
Yes, we offer round trip packages. You can book the Innova Crysta cab for both your journey to Shirdi and back to Pune at your convenience.</p>

<p><strong>Are the cabs well-maintained and clean?</strong><br/>
Absolutely! Our Innova Crysta cabs are regularly serviced and thoroughly cleaned before each trip to ensure maximum comfort and safety for our passengers.</p>

<p><strong>Is the driver experienced and knowledgeable about the route?</strong><br/>
Yes, our drivers are highly experienced and familiar with the best routes to Shirdi. They prioritize your safety and comfort during the journey.</p>

<p><strong>Do you offer one-way cab bookings from Pune to Shirdi?</strong><br/>
Yes, we provide one-way cab bookings from Pune to Shirdi. Please let us know your travel plans, and we’ll accommodate your needs.</p>

<p><strong>What payment methods do you accept for cab bookings?</strong><br/>
We accept various payment methods, including cash, credit/debit cards, and digital wallets. Please confirm your preferred payment option at the time of booking.</p>

<p><strong>Can I cancel or modify my booking?</strong><br/>
Yes, you can cancel or modify your booking. We recommend contacting our customer support team as soon as possible to discuss your options.</p>

<p><strong>Is there a limit on the number of passengers for the Innova Crysta cab?</strong><br/>
The Innova Crysta comfortably accommodates up to 6 to 7 passengers. For larger groups, we recommend booking multiple vehicles or opting for our mini-bus services.</p>

<p><strong>Do you provide additional amenities in the cab?</strong><br/>
Yes, our Innova Crysta cabs are equipped with essential amenities for a comfortable journey, including air conditioning, spacious seating, and ample luggage space.</p>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Urbania on Rent in Pune with Shiv Travels66</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Pune to Shirdi Innova Crysta Cab service: </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
