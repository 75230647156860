import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function UrbaniaOnRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "We recently rented the 17-seater Urbania from Shiv Travels66 for a family trip to Mahabaleshwar, and the experience was fantastic! The vehicle was clean, spacious, and extremely comfortable for our group. The driver was professional and knew the route well, making the journey stress-free. I would highly recommend Shiv Travels66 for anyone looking for reliable and comfortable group travel options in Pune.",
    name: "Mr. Rakesh Sharma"
  },
  {
    quote: "I booked the 13-seater Urbania for a corporate outing, and Shiv Travels66 exceeded our expectations. The entire process, from booking to the actual trip, was seamless. The Urbania was luxurious, with plenty of legroom and amenities that made the journey enjoyable for all of us. The driver was punctual and courteous. Shiv Travels66 is definitely my go-to for any future group travel needs.",
    name: "Mrs. Priya Deshmukh"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
          question: "What are the available seating options for Urbania on rent in Pune?",
          answer: "Shiv Travels66 offers Urbania vehicles with 10-seater, 13-seater, and 17-seater options, making them ideal for small to medium-sized groups.",
        },
        {
          question: "How can I book an Urbania on rent in Pune?",
          answer: "You can easily book an Urbania by calling us at [Your Contact Number], visiting our website, or sending us an inquiry through our contact form. We’ll confirm your booking and provide all the necessary details.",
        },
        {
          question: "Are your Urbania vehicles well-maintained and regularly serviced?",
          answer: "Yes, at Shiv Travels66, all our Urbania vehicles are thoroughly maintained and regularly serviced to ensure they are in excellent condition for your journey.",
        },
        {
          question: "Is a driver provided with the Urbania rental service?",
          answer: "Yes, all our Urbania rentals include a professional, experienced driver who will ensure a safe and comfortable journey.",
        },
        {
          question: "Can I rent an Urbania for an outstation trip from Pune?",
          answer: "Definitely! We offer Urbania rentals for outstation trips to popular destinations like Mahabaleshwar, Lonavala, Shirdi, and more. You can rent our vehicles for day trips or longer journeys.",
        },
        {
          question: "What is the rental cost of an Urbania in Pune?",
          answer: "The rental cost depends on factors such as the duration of the trip, seating capacity, and travel distance. For a personalized quote, feel free to contact us at [Your Contact Number].",
        },
        {
          question: "What amenities are included in the Urbania vehicles?",
          answer: "Our Urbania vehicles are fully equipped with air conditioning, comfortable seating, ample legroom, and other modern amenities to ensure a pleasant travel experience.",
        },
        {
          question: "Is there a limit on the distance I can travel with the Urbania rental?",
          answer: "No, there’s no specific limit on distance. However, the rental charges may vary based on the total kilometers covered. We provide transparent pricing details during the booking process.",
        },
        {
          question: "Do you offer Urbania rentals for corporate events or weddings?",
          answer: "Yes, we offer Urbania rentals for corporate events, weddings, and special occasions. Whether it's transporting guests or organizing a team outing, our vehicles are perfect for group travel.",
        },
        {
          question: "Can I rent an Urbania for airport transfers in Pune?",
          answer: "Yes, Shiv Travels66 provides Urbania rentals for airport transfers to and from Pune International Airport, offering a convenient and spacious option for group airport pick-ups and drop-offs.",
        },
      ];

      const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  


   
  return (
    <>
      <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-back1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Urbania on Rent in Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Urbania-on-rent-in-pune.jpg" alt="Urbania on rent in Pune" />
              </div>

              <div>
                <h1>Urbania on Rent in Pune with Shiv Travels66</h1>
                <p>
                  Experience comfort, luxury, and convenience with Shiv
                  Travels66. Our Urbania rental services in Pune are designed to
                  provide you with a premium travel experience at affordable
                  rates. Contact us today to book your Urbania and make your
                  journey memorable.
                </p>

                <h2>Luxury Force Urbania on Rent in Pune</h2>
                <p>
                  Looking for a Luxury Force Urbania on rent in Pune? Shiv
                  Travels66 offers premium Force Urbania rentals designed to
                  make your travel experience smooth, comfortable, and
                  luxurious. Whether it’s a family trip, corporate outing, or
                  special event, our luxury Urbania mini buses are perfect for
                  group travel. Enjoy spacious seating, modern amenities, and a
                  hassle-free booking process with Shiv Travels66.
                </p>

                <h2>Force Urbania Luxury Mini Bus on Rent</h2>
                <p>
                  Rent a Force Urbania Luxury Mini Bus with us for your next
                  trip. Our luxury mini buses are ideal for group travel,
                  providing comfortable seating for up to 13 or 17 passengers.
                  Enjoy premium features like air conditioning, ample legroom,
                  and professional drivers for a smooth ride. Shiv Travels66
                  ensures a reliable and comfortable journey for all your travel
                  needs in Pune.
                </p>

                <h2>13-Seater Urbania on Rent in Pune</h2>
                <p>
                  Need a 13-seater Urbania on rent in Pune? Shiv Travels66
                  offers the perfect solution for small group travel. Whether
                  you're planning a day trip, a family outing, or a business
                  event, our 13-seater Urbania ensures comfort and convenience.
                  With spacious interiors and advanced features, you’ll have a
                  pleasant ride no matter the destination.
                </p>

                <h2>17-Seater Urbania on Rent in Pune</h2>
                <p>
                  For larger groups, rent a 17-seater Urbania in Pune with Shiv
                  Travels66. Our 17-seater Force Urbania is designed to provide
                  maximum comfort for group travel. Whether it's a corporate
                  event, a wedding, or a long-distance trip, the spacious
                  Urbania is equipped with luxury features to enhance your
                  travel experience.
                </p>

                <h2>Urbania on Rent in Hadapsar</h2>
                <p>
                  If you're based in Hadapsar and need an Urbania on rent in
                  Hadapsar, Shiv Travels66 offers quick and convenient rental
                  services. Perfect for family trips, corporate outings, and
                  more, our Urbania rentals come with experienced drivers who
                  know the area, ensuring you reach your destination comfortably
                  and on time.
                </p>

                <h2>Urbania on Rent in Kharadi Pune</h2>
                <p>
                  Shiv Travels66 also offers Urbania on rent in Kharadi Pune,
                  serving both corporate and personal travel needs. Whether
                  you’re organizing a group outing, a business trip, or a
                  weekend getaway, our Urbania rentals provide a luxurious and
                  reliable travel option in Kharadi and nearby areas.
                </p>

                <h2>Force Urbania on Rent in Pimpri Chinchwad</h2>
                <p>
                  Traveling from Pimpri Chinchwad? Shiv Travels66 provides Force
                  Urbania on rent in Pimpri Chinchwad for a smooth and luxurious
                  travel experience. Our well-maintained fleet of Force Urbania
                  mini buses ensures that your group travels in comfort and
                  style, no matter the occasion.
                </p>

                <h2>10-Seater Force Urbania on Rent in Pune</h2>
                <p>
                  If you require a compact yet luxurious travel solution, Shiv
                  Travels66 offers a 10-seater Force Urbania on rent in Pune.
                  Ideal for smaller groups, this vehicle provides all the
                  comfort and amenities you need for a relaxing journey, whether
                  for a business meeting, family outing, or weekend trip.
                </p>

                <h2>Force Urbania Luxury Mini Bus on Rent in Pune</h2>
                <p>
                  Experience premium group travel with a Force Urbania Luxury
                  Mini Bus on rent in Pune. Shiv Travels66 provides top-notch
                  rental services, featuring comfortable seating, ample space,
                  and professional drivers for a stress-free ride. Whether it’s
                  a corporate event, sightseeing tour, or family function, our
                  Force Urbania is the perfect choice.
                </p>

                <h2>Force Urbania 17-Seater for Rent in Pune</h2>
                <p>
                  Planning a trip with a larger group? Rent a Force Urbania
                  17-seater in Pune with Shiv Travels66 for a spacious,
                  luxurious travel experience. Our 17-seater Urbania is equipped
                  with modern features and offers maximum comfort, making it
                  ideal for corporate outings, family trips, and special events.
                </p>

                <h2>Urbania on Rent in Pune Contact Number</h2>
                <p>
                  To book your Urbania on rent in Pune, simply contact Shiv
                  Travels66 at <a href="tel:+919764768866">+91 9764768866</a>.
                  We offer easy booking, reliable service, and flexible rental
                  plans to meet all your travel needs. Whether you need a 10,
                  13, or 17-seater Urbania, our team is ready to assist you in
                  planning the perfect trip.
                </p>
              </div>


              
 

              <div>
      <h1>Urbania On Rent in Pune Contact Number with Shiv Travels66</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Luxury Force Urbania on Rent in Pune</p>
<p>- Luxury Force Urbania on Rent In Pune</p>
<p>- Force Urbania Luxury Mini Bus On Rent</p>
<p>- 13 Seater Urbania on Rent in Pune</p>
<p>- 17-Seater Urbania on Rent in Pune</p>
<p>- Urbania on Rent in Hadapsar</p>


          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Urbania on Rent in Kharadi Pune</p>
<p>- Force Urbania on Rent in Pimpri Chinchwad</p>
<p>- 10 Seater Force Urbania On Rent In Pune</p>
<p>- Force Urbania Luxury Mini Bus on Rent in Pune</p>
<p>- Force Urbania 17 Seater for Rent Pune</p>
<p>- Urbania on Rent in Pune Contact Number</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
      <h2>Why Choose Us for Urbania on Rent in Pune with Shiv Travels66?</h2>
      <ul>
        <li>
          <strong>Wide Range of Options:</strong> Shiv Travels66 offers a variety
          of Urbania vehicles, including 10-seater, 13-seater, and 17-seater
          options, to cater to different group sizes. Whether it's a small family
          outing or a large corporate event, we have the right vehicle for your
          needs.
        </li>
        <li>
          <strong>Comfort and Luxury:</strong> Our Force Urbania vehicles are
          known for their luxurious interiors, featuring spacious seating, air
          conditioning, and modern amenities that ensure a comfortable and
          enjoyable travel experience.
        </li>
        <li>
          <strong>Experienced Drivers:</strong> All our rentals come with
          professional, experienced drivers who are familiar with local routes
          and destinations. You can sit back and relax while we handle your
          journey with safety and punctuality as our priority.
        </li>
        <li>
          <strong>Well-Maintained Fleet:</strong> At Shiv Travels66, we pride
          ourselves on maintaining a well-serviced fleet of vehicles. Our Urbania
          mini buses are regularly cleaned, inspected, and maintained to ensure
          they are in top condition for every trip.
        </li>
        <li>
          <strong>Flexible Rental Plans:</strong> Whether you need the Urbania for
          a short trip within Pune or a long-distance outstation journey, we
          offer flexible rental options that can be tailored to your specific
          travel plans and budget.
        </li>
        <li>
          <strong>Affordable Pricing:</strong> We provide competitive rates for
          our Urbania rentals, ensuring you get the best value for your money
          without compromising on comfort or service quality.
        </li>
        <li>
          <strong>24/7 Customer Support:</strong> Our dedicated team is available
          24/7 to assist with bookings, queries, and support. No matter the time,
          we're here to ensure that your travel experience with Shiv Travels66 is
          smooth and hassle-free.
        </li>
        <li>
          <strong>Ideal for All Occasions:</strong> Whether you're planning a
          corporate event, family vacation, wedding, or pilgrimage, our Urbania
          rentals offer the perfect solution for comfortable and convenient group
          travel in Pune.
        </li>
      </ul>
    </div>
      
    <div className="faq-container">
    <h2>FAQs for Urbania on Rent in Pune with Shiv Travels66</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 - Urbania on Rent in Pune</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
