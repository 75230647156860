import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PunetomahabaleshwarUrbania(){

    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote:"“I recently rented the Force Urbania from Shiv Travels66 for a family trip to Mahabaleshwar, and it was an amazing experience! The vehicle was spacious and comfortable, making our long journey enjoyable. Our driver was professional and knew all the best spots to stop along the way. I highly recommend Shiv Travels66 for anyone looking to rent a vehicle for their trip!",
    name: "- Mrs. Priya Deshmukh"
  },
  {
    quote: "“Shiv Travels66 provided an excellent service for our Pune to Mahabaleshwar trip. The Urbania we rented was in perfect condition, and the ride was smooth throughout. Our driver was punctual and very friendly, making our journey even more pleasant. Ill definitely be using their services again for future trips!” ",
    name: " -Mr. Rohan Kulkarni"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What types of Urbania vehicles are available for rent from Pune to Mahabaleshwar?",
            "answer": "We offer various options, including 10-seater, 13-seater, and 17-seater Force Urbania vehicles, to accommodate different group sizes and travel needs."
        },
        {
            "question": "How do I book an Urbania on rent for my trip from Pune to Mahabaleshwar?",
            "answer": "You can easily book your Urbania by contacting us through our website or calling us directly at [Your Contact Number]. Our team will assist you with the booking process and answer any queries you may have."
        },
        {
            "question": "Are the Urbania vehicles air-conditioned?",
            "answer": "Yes, all our Force Urbania vehicles are fully air-conditioned, ensuring a comfortable travel experience even during the warm months."
        },
        {
            "question": "Can I customize my itinerary for the Pune to Mahabaleshwar trip?",
            "answer": "Absolutely! We offer customizable travel packages, allowing you to choose your itinerary based on the places you want to visit and the duration of your stay."
        },
        {
            "question": "Are the drivers experienced and knowledgeable about the route?",
            "answer": "Yes, all our drivers are experienced professionals familiar with the Pune to Mahabaleshwar route. They prioritize safety and punctuality while ensuring a pleasant journey for our passengers."
        },
        {
            "question": "Is there a minimum rental period for the Urbania?",
            "answer": "Yes, typically, there is a minimum rental period of one day. However, for specific requirements or longer trips, please contact us for detailed arrangements."
        },
        {
            "question": "What amenities can I expect in the Urbania?",
            "answer": "Our Force Urbania vehicles come equipped with spacious seating, air conditioning, charging ports, and entertainment systems to ensure a comfortable journey."
        },
        {
            "question": "How much does it cost to rent an Urbania for the Pune to Mahabaleshwar trip?",
            "answer": "The rental cost varies based on factors like the type of vehicle, duration of the trip, and any additional services required. For an accurate quote, please contact us directly."
        },
        {
            "question": "What is your cancellation policy?",
            "answer": "We have a flexible cancellation policy. Please contact our customer service team for details on cancellations and any applicable fees."
        },
        {
            "question": "Is there 24/7 customer support available during the trip?",
            "answer": "Yes, we provide 24/7 customer support to assist you with any queries or emergencies during your trip. Our team is always ready to help!"
        }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];



    return(
        <>
          <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home| Pune to Shirdi Tempo Traveller on Rent|Pune Tempo Traveller Hire for Outstation
               | Tempo Traveller Hire for Wedding in Pune</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
             {/* <!-- page-title --> */}
      <section class="page-title centred car-back1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travles66</span>
            <h1>Pune to Mahabaleshwar Urbania on Rent</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg" alt="Pune to Mahabaleshwar Urbania on rent in Pune" />
              </div>
 
              <div>
              <h1>Pune to Mahabaleshwar Urbania on Rent with Shiv Travels66</h1>
<p>Planning a trip from Pune to Mahabaleshwar and need comfortable and reliable group transportation? Shiv Travels66 offers Urbania on rent for a seamless travel experience. Our luxury Force Urbania mini buses are perfect for group travel, providing spacious seating, modern amenities, and professional drivers to ensure a smooth and enjoyable journey.</p>

<h2>Pune to Mahabaleshwar Urbania on Rent Contact Number</h2>
<p>Looking for a reliable Urbania on rent for your Pune to Mahabaleshwar trip? Look no further than Shiv Travels66! We offer spacious and luxurious Force Urbania vehicles that are perfect for group travel. Whether it's a family vacation or a corporate outing, our Urbania ensures a comfortable journey. Call us at [+91 9764768866] to book your Pune to Mahabaleshwar Urbania on rent today!</p>

<h2>Force Urbania on Rent Near Me</h2>
<p>If you're searching for Force Urbania on rent near me, Shiv Travels66 has got you covered. We provide Urbania rentals for local and outstation trips, including popular routes like Pune to Mahabaleshwar, Pune to Panchgani, and more. Enjoy comfortable seating, air conditioning, and modern amenities for a seamless travel experience. Book your Force Urbania by contacting us today!</p>

<h2>Force Urbania 17-Seater for Rent</h2>
<p>Shiv Travels66 offers the Force Urbania 17-seater for rent, perfect for larger groups. Whether it’s a family reunion, wedding party, or corporate event, our 17-seater Urbania ensures ample space and comfort. Call us at [+91 9764768866] for booking and availability details.</p>

<h2>Pune to Mahabaleshwar Tour Package 3 Day</h2>
<p>Planning a 3-day getaway to Mahabaleshwar? Shiv Travels66 provides Pune to Mahabaleshwar tour packages with our luxury Urbania vehicles. Our packages include round-trip travel, professional drivers, and the flexibility to explore Mahabaleshwar’s top attractions like Venna Lake, Pratapgad Fort, and Elephant's Head Point. Contact us for more details and to book your 3-day Pune to Mahabaleshwar tour package today!</p>

<h2>Pune to Panchgani Urbania on Rent for Trip</h2>
<p>Make your Pune to Panchgani trip hassle-free with Shiv Travels66’s Urbania on rent. Our Force Urbania offers comfortable seating, air conditioning, and plenty of space for a smooth journey. Whether you’re traveling with friends or family, enjoy the scenic drive to Panchgani and explore its popular spots like Sydney Point and Table Land. Call us at [+91 9764768866] to rent your Urbania today!</p>

<h2>Pune to Panchgani Urbania on Rent for Trip Near Me</h2>
<p>Looking for a reliable Urbania rental for a trip from Pune to Panchgani? Shiv Travels66 offers convenient and comfortable travel solutions with our Urbania vehicles. Whether you're located in Pune, Hadapsar, or Kharadi, our Urbania on rent is available near you. Contact us today to book your trip to Panchgani with ease!</p>

<h2>Pune to Tapola Urbania Packages</h2>
<p>Explore the beautiful landscapes of Tapola, also known as the “Mini Kashmir,” with Shiv Travels66’s Pune to Tapola Urbania packages. Enjoy a luxury ride in our Force Urbania vehicles, perfect for group trips. Our packages include comfortable travel, professional drivers, and plenty of space to relax during your journey to this serene destination. For bookings and package details, call [+91 9764768866] now!</p>

<h2>Urbania on Rent in Viman Nagar</h2>
<p>Shiv Travels66 offers luxury Force Urbania vehicles to cater to your group travel needs. Whether it's a local trip or an outstation journey, our Urbania buses are equipped with modern amenities to ensure a smooth and comfortable ride. Contact us today to book your Urbania in Viman Nagar for family outings, corporate trips, or special events.</p>

<h2>Pune to Kolhapur Urbania Bus on Rent</h2>
<p>Planning a trip from Pune to Kolhapur? Shiv Travels66 offers Urbania buses on rent for a comfortable and convenient journey. Our Force Urbania buses come in various seating capacities, perfect for group travel. Enjoy a stress-free ride to Kolhapur, whether it's for sightseeing, religious trips, or family vacations. Contact us to book your Pune to Kolhapur Urbania bus today!</p>

<h2>Hire Urbania Bus on Rent with Shiv Travels</h2>
<p>Looking to hire an Urbania bus on rent? Shiv Travels66 offers a fleet of well-maintained, luxurious Force Urbania vehicles for both local and long-distance travel. With options ranging from 10-seater to 17-seater buses, we cater to all group sizes and events. Whether you’re planning a wedding, corporate event, or family trip, Shiv Travels66 is your trusted partner for premium Urbania rentals. Contact us for bookings and inquiries.</p>

<h2>Pune to Goa Urbania on Rent</h2>
<p>Make your Pune to Goa trip memorable with Urbania on rent from Shiv Travels66. Our luxury Urbania buses offer comfortable seating, spacious interiors, and professional drivers to ensure a hassle-free journey. Whether you're heading to Goa for a holiday or a group event, we provide a seamless travel experience with our top-notch Urbania rentals. Call us to book your Pune to Goa Urbania today!</p>

<h2>Force Urbania 10-Seater on Rent in Pune</h2>
<p>Need a reliable and comfortable 10-seater Urbania on rent in Pune? Shiv Travels66 provides Force Urbania rentals, perfect for small groups, family trips, or corporate outings. Our 10-seater Urbania vehicles are well-maintained and offer luxurious interiors for a smooth journey. Contact us to rent a 10-seater Urbania for your next trip in Pune or nearby destinations.</p>

<h2>Pune to Sangli Satara Urbania on Rent</h2>
<p>Travel from Pune to Sangli or Pune to Satara in comfort with Shiv Travels66’s Urbania on rent. Our luxury Urbania buses are ideal for long-distance travel, offering spacious seating and modern amenities. Whether it's a family vacation, a business trip, or a pilgrimage, Shiv Travels66 ensures a safe and comfortable journey. Book your Urbania for Pune to Sangli or Satara today!</p>

              </div>

      


              <div>
      <h1>Pune to Mahabaleshwar Urbania on Rent Contact Number with Shiv Travels66 : - </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Force Urbania on Rent Near me</p>
<p>- Force Urbania 17 Seater for Rent</p>
<p>- Pune to Mahabaleshwar Tour package 3 day</p>
<p>- Pune to Panchgani Urbania on Rent for trip</p>
<p>- Pune to Panchgani Urbania on Rent for trip near me</p>


          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Kolhapur Urbania bus on rent</p>
<p>- Hire Urbania bus on Rent with Shiv Travels</p>
<p>- Pune to Goa Urbania on Rent</p>
<p>- Force Urbania 10 Seater on Rent in Pune</p>
<p>- Pune to Sangli Satara Urbania on Rent</p>
<p>- Urbania On Rent in Viman Nagar</p>
<p>- Pune to Tapola Urbania Packages</p>



          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
     
    <h2>Why Choose Shiv Travels66 for Pune to Mahabaleshwar Urbania on Rent?</h2>
<p>
    Choosing Shiv Travels66 for your Pune to Mahabaleshwar Urbania on Rent comes with several advantages that make your travel experience both convenient and memorable. Here are the top reasons why Shiv Travels66 is the best option for your next trip to Mahabaleshwar:
</p>

<ol>
    <li>
        <strong>Luxurious and Comfortable Urbania Buses</strong>
        <p>We offer luxury Force Urbania vehicles that are designed to provide maximum comfort. With spacious seating, air conditioning, and modern amenities, our Urbania buses ensure a smooth and enjoyable journey, whether you're traveling with family, friends, or colleagues.</p>
    </li>
    <li>
        <strong>Multiple Seating Options</strong>
        <p>Shiv Travels66 provides various seating capacities to meet your group travel needs. Choose from 10-seater, 13-seater, or 17-seater Urbania buses, making it easy to accommodate groups of all sizes for your trip from Pune to Mahabaleshwar.</p>
    </li>
    <li>
        <strong>Professional and Experienced Drivers</strong>
        <p>Our drivers are highly trained and experienced in handling long-distance routes. They are familiar with the Pune to Mahabaleshwar route and will ensure a safe, smooth, and timely journey, allowing you to relax and enjoy the scenic beauty along the way.</p>
    </li>
    <li>
        <strong>Affordable and Transparent Pricing</strong>
        <p>At Shiv Travels66, we offer competitive pricing with no hidden charges. Our transparent pricing structure ensures that you get the best value for your money. Whether you need a one-day trip or a longer stay in Mahabaleshwar, we offer customized rental packages to fit your budget.</p>
    </li>
    <li>
        <strong>Timely and Reliable Service</strong>
        <p>We pride ourselves on punctuality and reliability. With Shiv Travels66, you can be confident that our Urbania buses will arrive on time and take you to your destination without any delays. Our 24/7 customer support ensures that you have assistance throughout your journey.</p>
    </li>
    <li>
        <strong>Flexible Booking Options</strong>
        <p>Whether you’re planning a weekend getaway, a family vacation, or a corporate retreat, we provide flexible booking options. You can rent our Urbania buses for a day trip or for multiple days, based on your travel itinerary.</p>
    </li>
    <li>
        <strong>Scenic Routes and Stopovers</strong>
        <p>Our drivers are familiar with the best scenic spots on the way to Mahabaleshwar and can suggest stops for you to take in the beauty of the Western Ghats. Whether you want to visit Panchgani, Mapro Garden, or other tourist spots, we provide a well-planned travel route for your trip.</p>
    </li>
    <li>
        <strong>Customizable Travel Packages</strong>
        <p>At Shiv Travels66, we understand that every group has unique travel requirements. That's why we offer customizable travel packages, allowing you to tailor your journey based on the places you want to visit and the duration of your trip.</p>
    </li>
    <li>
        <strong>Safe and Well-Maintained Vehicles</strong>
        <p>Safety is our top priority. All our Force Urbania buses are regularly inspected and maintained to ensure they are in perfect condition. From seat belts to safety equipment, we take extra care to provide a safe travel experience for our passengers.</p>
    </li>
    <li>
        <strong>Customer Satisfaction</strong>
        <p>Shiv Travels66 has a reputation for offering excellent customer service. Our top priority is customer satisfaction, and we go above and beyond to ensure that your journey from Pune to Mahabaleshwar is comfortable, enjoyable, and stress-free.</p>
    </li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Urbania on Rent in Pune with Shiv Travels66</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 - Urbania on Rent in Pune</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
        </>
    )
}