import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";
export default function PuneToMahabalwshwarInnovaCrystaCab() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently hired an Innova Crysta from Shiv Travels66 for our family trip to Mahabaleshwar, and I couldn't be happier with the experience! The vehicle was in excellent condition, spacious, and very comfortable for our group. Our driver was friendly and knowledgeable about the area, making our journey enjoyable and hassle-free. I highly recommend Shiv Travels66 for anyone looking to travel from Pune to Mahabaleshwar!",
    name: "Mrs. Priya Deshmukh"
  },
  {
    quote: "Shiv Travels66 provided an outstanding service for our trip to Mahabaleshwar. The Innova Crysta was perfect for our group of friends, and the ride was smooth and comfortable. The booking process was straightforward, and our driver arrived on time. He even suggested some great spots to visit along the way! I will definitely choose Shiv Travels66 again for future trips.",
    name: " Mr. Rahul Patil"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
        {
            "question": "What is the distance between Pune and Mahabaleshwar?",
            "answer": "The distance between Pune and Mahabaleshwar is approximately 120 kilometers, and the journey typically takes around 3 to 4 hours, depending on traffic and road conditions."
          },
          {
            "question": "How can I book an Innova Crysta cab from Pune to Mahabaleshwar?",
            "answer": "You can book your Innova Crysta cab by contacting us via our website, phone, or email. Provide your travel details, and our team will assist you in securing your booking."
          },
          {
            "question": "What are the rental charges for the Innova Crysta cab?",
            "answer": "Rental charges vary based on the duration of the trip, vehicle availability, and any additional services you may require. Please contact us for a personalized quote."
          },
          {
            "question": "Is the Innova Crysta cab air-conditioned?",
            "answer": "Yes, all our Innova Crysta vehicles are fully air-conditioned, ensuring a comfortable ride for you and your companions."
          },
          {
            "question": "Can I hire the Innova Crysta for a round trip to Mahabaleshwar?",
            "answer": "Yes, we offer round-trip services from Pune to Mahabaleshwar. You can discuss your itinerary with us, and we’ll tailor the package to fit your needs."
          },
          {
            "question": "What is included in the cab service?",
            "answer": "Our cab service includes the use of the Innova Crysta, a professional driver, and all fuel charges. Additional services like toll fees and parking charges may apply."
          },
          {
            "question": "Are your drivers experienced and knowledgeable?",
            "answer": "Absolutely! Our drivers are experienced, courteous, and knowledgeable about the routes and popular attractions in Mahabaleshwar, ensuring a safe and enjoyable journey."
          },
          {
            "question": "What payment methods do you accept?",
            "answer": "We accept various payment methods, including cash, bank transfers, and digital payment platforms. You can choose the option that works best for you during the booking process."
          },
          {
            "question": "Can I make changes to my booking?",
            "answer": "Yes, you can make changes to your booking. However, please notify us as soon as possible to accommodate your requests. Our team will do their best to assist you."
          },
          {
            "question": "What if my plans change and I need to cancel my booking?",
            "answer": "We understand that plans can change. Our cancellation policy is flexible, and we recommend contacting us at least 24 hours in advance for cancellations to avoid any charges."
          }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]

    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

  
  

   
  return (
    <>
  <Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>
     
      {/* <!-- page-title --> */}
      <section class="page-title centred background1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune to Mahabaleshwar Innova Crysta Cabs</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page ">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-to Mahabaleshwar Innova-Crysta-Cab.jpg" alt="Pune to Mahabaleshwar Innova-Crysta-Cab" />
              </div>

              <div>
              <h1>Pune to Mahabaleshwar Innova Crysta Cab with Shiv Travels66</h1>

<p>Look no further than Shiv Travels66 for the most comfortable and reliable Innova Crysta cab service. With its stunning landscapes, lush greenery, and pleasant weather, Mahabaleshwar is the perfect destination for a family vacation, a romantic getaway, or a fun trip with friends.</p>

<h2>Pune to Mahabaleshwar Cab Packages</h2>
<p>At Shiv Travels66, we provide a variety of Pune to Mahabaleshwar cab packages designed to cater to different travel requirements. Whether you're going for a quick trip or a longer stay, we have options that suit every itinerary:</p>
<ul>
    <li><strong>One-Day Package:</strong> Perfect for a short visit to explore the key attractions in Mahabaleshwar.</li>
    <li><strong>Two-Day Package:</strong> Enjoy a relaxed experience, allowing you to visit more places at a leisurely pace.</li>
    <li><strong>Three-Day Package:</strong> Ideal for those who want to immerse themselves in the beauty of Mahabaleshwar and visit its lesser-known spots.</li>
</ul>

<h2>Pune to Mahabaleshwar Innova Rate</h2>
<p>Our Pune to Mahabaleshwar Innova rates are competitive and designed to fit your budget. The pricing is transparent, ensuring you know exactly what you’re paying for. Here’s a quick overview:</p>
<ul>
    <li><strong>Standard Rate:</strong> Our rates include fuel, driver charges, and all applicable taxes.</li>
    <li><strong>Customization:</strong> You can customize your package based on the number of days, specific attractions, and any additional services you may need.</li>
</ul>

<h2>Pune to Mahabaleshwar Cab for 3 Days</h2>
<p>If you're looking for a more extended stay, our Pune to Mahabaleshwar cab for 3 days package is the perfect choice. This package allows you to explore the stunning landscapes, enjoy local cuisine, and visit attractions like:</p>
<ul>
    <li><strong>Venna Lake:</strong> A serene spot for boating and relaxation.</li>
    <li><strong>Mapro Garden:</strong> Enjoy fresh strawberries and a delightful ambiance.</li>
    <li><strong>Elephant's Head Point:</strong> Witness breathtaking views and capture memorable photos.</li>
</ul>

<h2>Pune to Mahabaleshwar Cab Service</h2>
<p>Escape the city and immerse yourself in nature with our Pune to Mahabaleshwar cab service. At Shiv Travels66, we are committed to providing you with a seamless travel experience, ensuring that your journey is as enjoyable as your destination.</p>

<h2>Pune to Mahabaleshwar Car Rental</h2>
<p>When you choose our Pune to Mahabaleshwar car rental service, you gain access to a wide range of vehicles that cater to your specific needs. Whether you’re traveling solo, with family, or in a large group, we have options that fit all preferences:</p>
<ul>
    <li><strong>Sedans:</strong> Perfect for couples or small families looking for comfort.</li>
    <li><strong>SUVs:</strong> Ideal for those wanting more space and luggage capacity.</li>
    <li><strong>Innova:</strong> Great for group travel with ample seating and legroom.</li>
</ul>

<h2>Pune to Mahabaleshwar Cab Charges</h2>
<p>Understanding the Pune to Mahabaleshwar cab charges is essential for planning your trip. Our pricing is transparent, with no hidden fees. Charges typically include:</p>
<ul>
    <li><strong>Base Fare:</strong> The standard rate for hiring the vehicle.</li>
    <li><strong>Distance-Based Charges:</strong> Additional costs based on the total kilometers traveled.</li>
    <li><strong>Driver Fees:</strong> Professional driver charges included in the fare.</li>
</ul>

<h2>Cab Booking Pune to Mahabaleshwar</h2>
<p>Booking your cab with us is quick and straightforward. Our cab booking for Pune to Mahabaleshwar can be done in just a few steps:</p>
<ul>
    <li><strong>Contact Us:</strong> Reach out via phone or our online booking platform.</li>
    <li><strong>Choose Your Vehicle:</strong> Select from our diverse fleet according to your needs.</li>
    <li><strong>Confirm Your Booking:</strong> Provide travel details, and we’ll handle the rest!</li>
</ul>

<h2>Mahabaleshwar Taxi from Pune</h2>
<p>Our Mahabaleshwar taxi from Pune service ensures that you arrive at your destination comfortably and on time. We cater to:</p>
<ul>
    <li><strong>One-Day Trips:</strong> Explore the highlights of Mahabaleshwar in a day.</li>
    <li><strong>Extended Tours:</strong> Enjoy a more leisurely exploration with multiple stops and attractions.</li>
    <li><strong>Group Bookings:</strong> We can accommodate larger groups, making your trip more enjoyable.</li>
</ul>

<h2>Pune to Mahabaleshwar Cab Cost</h2>
<p>The Pune to Mahabaleshwar cab cost varies based on factors such as the type of vehicle, the duration of the trip, and specific package deals. We offer:</p>
<ul>
    <li><strong>Competitive Rates:</strong> Get the best value for your journey.</li>
    <li><strong>Seasonal Discounts:</strong> Take advantage of our special offers during peak travel seasons.</li>
    <li><strong>Flexible Packages:</strong> Choose a package that fits your budget and travel plans.</li>
</ul>

<h2>Taxi Service from Pune to Mahabaleshwar</h2>
<p>Our taxi service from Pune to Mahabaleshwar is designed to provide convenience and comfort. With:</p>
<ul>
    <li><strong>Professional Drivers:</strong> Our experienced drivers ensure a safe and pleasant journey.</li>
    <li><strong>Well-Maintained Vehicles:</strong> All our cabs are regularly serviced for reliability.</li>
    <li><strong>24/7 Support:</strong> Our customer support team is available around the clock to assist you.</li>
</ul>

<h2>Taxi for Mahabaleshwar from Pune</h2>
<p>Our taxi for Mahabaleshwar from Pune is just a call away. Experience breathtaking views, lush greenery, and refreshing weather as you journey to this beautiful hill station.</p>

              </div>

            

              <div>
      <h1>Pune to Mahabaleshwar Innova Crysta Cab with Shiv Travels66 Contact Number: </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune To Mahabaleshwar Innova Cab Booking</p>

<p>- Pune to Mahabaleshwar cab packages</p>

<p>- Pune to Mahabaleshwar Innova rate</p>

<p>- Pune to Mahabaleshwar cab for 3 days</p>

<p>- Pune to Mahabaleshwar cab</p>

<p>- Pune to Mahabaleshwar cab package</p>

<p>- Pune to Mahabaleshwar taxi</p>

<p>- Pune to Mahabaleshwar cab price</p>

<p>- Pune to Mahabaleshwar cab for 2 days</p>





          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- Pune to Mahabaleshwar car package</p>

<p>- Pune to Mahabaleshwar cab service</p>

<p>- Pune to Mahabaleshwar car rental</p>

<p>- Pune to Mahabaleshwar cab charges</p>

<p>- Cab booking Pune to Mahabaleshwar</p>

<p>- Mahabaleshwar taxi from Pune</p>

<p>- Pune to Mahabaleshwar cab cost</p>

<p>- Taxi service from Pune to Mahabaleshwar</p>

<p>- Taxi for Mahabaleshwar from Pune</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Pune to Mahabaleshwar Innova Crysta Cab with Shiv Travels66?</h1>

<p>When planning your trip from Pune to Mahabaleshwar, selecting the right transportation can enhance your experience significantly. Here’s why Shiv Travels66 should be your go-to choice for Pune to Mahabaleshwar Innova Crysta cab services:</p>

<ul>
    <li><strong>Comfortable Travel Experience:</strong> Our Innova Crysta vehicles are renowned for their spacious interiors, plush seating, and advanced features, ensuring a comfortable ride for you and your companions. Whether you're traveling with family or friends, our cabs provide ample space for everyone, making your journey enjoyable.</li>

    <li><strong>Experienced and Professional Drivers:</strong> We pride ourselves on having a team of skilled and courteous drivers who know the best routes to Mahabaleshwar. With their extensive knowledge of the area, they ensure timely arrivals and provide valuable insights about local attractions, making your journey not just a commute but a delightful experience.</li>

    <li><strong>Safety First:</strong> Your safety is our top priority. All our Innova Crysta vehicles undergo rigorous maintenance checks to ensure they are in top-notch condition. Our drivers adhere to all safety protocols and traffic regulations, giving you peace of mind throughout your journey.</li>

    <li><strong>Transparent Pricing:</strong> At Shiv Travels66, we believe in transparency. Our Pune to Mahabaleshwar Innova Crysta cab pricing is straightforward with no hidden charges. You can easily get a quote based on your travel dates and requirements, allowing you to budget effectively for your trip.</li>

    <li><strong>Customized Packages:</strong> We understand that every traveler has unique needs. That’s why we offer customizable packages for your Innova Crysta cab rental. Whether you want a one-day trip or an extended stay, we can tailor our services to fit your itinerary and preferences.</li>

    <li><strong>24/7 Customer Support:</strong> Our dedicated customer support team is available around the clock to assist you with bookings, inquiries, or any concerns you may have before, during, or after your trip. We strive to ensure that your experience with us is seamless and hassle-free.</li>

    <li><strong>Convenient Booking Process:</strong> Booking your Pune to Mahabaleshwar Innova Crysta cab with us is easy and quick. You can book online or over the phone, allowing you to secure your travel plans at your convenience.</li>

    <li><strong>Local Insights and Recommendations:</strong> As a local travel service, we can provide you with insider tips and recommendations for places to visit, eat, and explore in Mahabaleshwar. Our drivers are often well-versed in the best spots, ensuring you have a memorable experience.</li>

    <li><strong>Satisfaction Guaranteed:</strong> We prioritize customer satisfaction above all else. Our goal is to ensure you have a delightful experience traveling from Pune to Mahabaleshwar. We are committed to addressing your needs and making your journey enjoyable.</li>

    <li><strong>Flexible Cancellation Policies:</strong> We understand that plans can change. That’s why we offer flexible cancellation policies, giving you the freedom to modify or cancel your booking if necessary.</li>
</ul>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Shiv Travels66 regarding Pune to Mahabaleshwar Innova Crysta Cab services ?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 regarding the Pune to Mahabaleshwar Innova Crysta Cab service:</h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
            {/* ========= */}
          </div>

          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
