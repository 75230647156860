import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import { Helmet } from "react-helmet";

export default function PuneTempoHireOnOutstationInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "I recently hired a Tempo Traveller from Shiv Travels66 for an outstation trip to Mahabaleshwar with my family. The booking process was seamless, and the vehicle was in excellent condition. Our driver was friendly and knowledgeable, making our journey enjoyable. We felt safe and comfortable throughout the trip. I highly recommend Shiv Travels66 for anyone looking for reliable transportation!",
    name: " Mr. Rohan Mehta"
  },
  {
    quote: "We rented a 17-seater Tempo Traveller from Shiv Travels66 for a weekend getaway to Lonavala, and it was an amazing experience! The vehicle was spacious and well-maintained, and our driver was punctual and professional. He knew the best routes and ensured we reached our destination safely. I will definitely be using their services again for our future trips!",
    name: "Mrs.Sneha Desai"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true
  };


    const faqData = [
        {
            "question": "What are the available seating options for Urbania on rent in Pune?",
            "answer": "Shiv Travels66 offers Urbania vehicles with 10-seater, 13-seater, and 17-seater options, making them ideal for small to medium-sized groups."
          },
          {
            "question": "How can I book an Urbania on rent in Pune?",
            "answer": "You can easily book an Urbania by calling us at [Your Contact Number], visiting our website, or sending us an inquiry through our contact form. We’ll confirm your booking and provide all the necessary details."
          },
          {
            "question": "Are the Urbania vehicles air-conditioned?",
            "answer": "Yes, all our Urbania vehicles come equipped with air conditioning to ensure a comfortable journey."
          },
          {
            "question": "What is the rental cost for Urbania on rent in Pune?",
            "answer": "The rental cost varies based on factors such as vehicle type, seating capacity, distance, and duration of hire. Please contact us for a tailored quote."
          },
          {
            "question": "Can I book an Urbania for a one-way trip?",
            "answer": "Yes, we offer both one-way and round-trip options for your convenience."
          },
          {
            "question": "Do I need to provide any documents to book an Urbania?",
            "answer": "You will need to provide a valid ID proof (like an Aadhaar card or driving license) along with your travel details."
          },
          {
            "question": "Is a driver included with the Urbania rental?",
            "answer": "Yes, every Urbania rental includes an experienced and professional driver to ensure a safe and comfortable journey."
          },
          {
            "question": "Are the Urbania vehicles well-maintained?",
            "answer": "Absolutely! All our Urbania vehicles are regularly serviced and inspected for safety and comfort."
          },
          {
            "question": "Can I customize my itinerary for an Urbania trip?",
            "answer": "Yes, we offer customizable itineraries to suit your travel preferences."
          },
          {
            "question": "What are the payment options for booking an Urbania?",
            "answer": "We offer various payment options, including online payments and cash on delivery. Please inquire for more details."
          }
      ];

     const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]


    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };
  

  const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];

  
   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home| Pune Tempo Traveller Hire for Outstation |Tempo Traveller Hire for Wedding in Pune
               | Mini Bus On Rent in Pune |Innova Crysta on Rent in Pune | Pune to Goa Urbania On Rent</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content="  Pune Tempo Traveller Hire for Outstation ,Tempo Traveller Hire for Wedding in Pune, Mini Bus On Rent in Pune
              ,Innova Crysta on Rent in Pune, Pune to Goa Urbania On Rent" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-img2">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Pune Tempo Traveller Hire for Outstation</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex  container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Pune-Tempo Traveller-Hire-for Outstation.jpg" alt="Urbania on rent in Pune" />
              </div>

              <div>
              <h1>Pune Tempo Traveller Hire for Outstation with Shiv Travels66</h1>

<p>Shiv Travels66 offers the most reliable and comfortable Pune Tempo Traveller hire for outstation journeys. Whether you're headed for a family vacation, corporate outing, or pilgrimage, our well-maintained Tempo Travellers are ideal for long-distance travel. With options ranging from 9-seater to 17-seater Tempo Travellers, we cater to groups of all sizes, ensuring a seamless travel experience.</p>

<h2>Outstation Tempo Traveller in Pune</h2>
<p>When planning an outstation trip from Pune, consider Shiv Travels66 for reliable and comfortable Tempo Traveller rentals. Our fleet of well-maintained Tempo Travellers is perfect for group travel, ensuring a seamless and enjoyable journey to your chosen destination. Whether you’re heading to Lonavala, Mahabaleshwar, or Shirdi, we have the ideal vehicle to accommodate your group size and travel needs.</p>

<h2>Tempo Traveller 12-Seater Rent Per Km in Pune</h2>
<p>For those looking to hire a 12-seater Tempo Traveller in Pune, Shiv Travels66 offers competitive rates based on distance traveled. Our 12-seater vehicles are perfect for family outings or small group trips, providing ample space and comfort for passengers. Contact us to find out the rent per km for our Tempo Travellers and enjoy a hassle-free booking experience.</p>

<h2>Pune Tempo Traveller Hire for Outstation</h2>
<p>At Shiv Travels66, we specialize in Pune Tempo Traveller hire for outstation trips. Our well-maintained vehicles are equipped to provide comfort and safety during your journey. With a variety of seating capacities, including 9, 12, and 17-seater options, you can choose the right vehicle for your travel plans. Enjoy the flexibility of customizable itineraries, making your outstation travel experience enjoyable and convenient.</p>

<h2>Luxury Tempo Traveller on Rent in Pune</h2>
<p>Experience comfort and style with our Luxury Tempo Traveller on rent in Pune. Perfect for corporate outings, weddings, or family trips, our luxury vehicles come equipped with modern amenities, including plush seating, air conditioning, and entertainment systems. Book your luxury Tempo Traveller with Shiv Travels66 and enjoy a first-class travel experience that ensures you arrive at your destination relaxed and rejuvenated.</p>

<h2>Luxury Tempo Traveller on Rent in Pune Near Me</h2>
<p>Look no further! Shiv Travels66 provides convenient rental services with a fleet of luxury Tempo Travellers ready to cater to your travel needs. Whether it’s for a special event or a comfortable journey, our luxury vehicles offer the perfect solution for group travel in and around Pune.</p>

<h2>Tempo Traveller on Rent in Pune Rate Per Km</h2>
<p>When looking for a Tempo Traveller on rent in Pune, understanding the rate per km is essential for budgeting your trip. At Shiv Travels66, we offer transparent pricing with competitive rates based on the distance you plan to travel. Our experienced team is ready to provide you with a detailed quote that fits your travel requirements, ensuring you receive the best value for your money.</p>

<h2>20 Seater Tempo Traveller on Rent in Pune</h2>
<p>For larger groups, our 20-seater Tempo Traveller on rent in Pune is an excellent option. Whether you're organizing a corporate event, a wedding, or a family reunion, our spacious 20-seater vehicles ensure everyone travels together comfortably. With amenities designed for group travel, including air conditioning and comfortable seating, Shiv Travels66 is your go-to choice for large group rentals in Pune.</p>

<h2>13 Seater Tempo Traveller on Rent in Pune</h2>
<p>Shiv Travels66 offers spacious and comfortable vehicles perfect for family trips, group outings, or short vacations. Our 13-seater Tempo Travellers come equipped with modern amenities, ensuring a pleasant journey for all passengers. Whether you’re heading for a day trip or a weekend getaway, our well-maintained vehicles will meet your travel needs.</p>

<h2>Tempo Traveller on Rent in Pune for Picnic</h2>
<p>Our Tempo Traveller on rent in Pune for picnic outings is the ideal choice. With ample space for passengers and luggage, you can enjoy a fun-filled day at your favorite picnic spot without worrying about transportation. Choose Shiv Travels66 for a comfortable ride to popular picnic destinations around Pune, ensuring everyone travels together in style and comfort.</p>

<h2>17 Seater Tempo Traveller on Rent in Pune</h2>
<p>For larger groups, we provide a 17-seater Tempo Traveller on rent in Pune. Perfect for family reunions, corporate events, or school trips, our spacious Tempo Travellers ensure a comfortable and enjoyable ride for all passengers. Equipped with air conditioning and ample legroom, our 17-seater options cater to your group travel needs. Contact Shiv Travels66 today to book your 17-seater vehicle for your next adventure!</p>

<h2>Pune Mumbai Tempo Traveller Hire</h2>
<p>Our Pune Mumbai Tempo Traveller hire service provides a convenient and comfortable way to reach your destination. Whether you're visiting for business or leisure, our well-maintained Tempo Travellers offer a smooth ride with plenty of space for passengers and luggage. Enjoy the scenic route with a reliable driver who knows the best roads to take.</p>

<h2>Pune Darshan Tempo Traveller Hire</h2>
<p>Explore the cultural heritage and attractions of Pune with our Pune Darshan Tempo Traveller hire service. Ideal for tourists and locals alike, our Tempo Travellers are perfect for a guided city tour, allowing you to visit popular landmarks and hidden gems at your own pace. Enjoy the comfort of our spacious vehicles and make the most of your Pune sightseeing experience with Shiv Travels66.</p>

<h2>17-Seater Bus on Rent in Pune</h2>
<p>If you need a larger vehicle, we also offer a 17-seater bus on rent in Pune. Our buses are perfect for school trips, corporate outings, and group travel, providing comfortable seating and ample space for everyone. Enjoy a hassle-free journey with our professional drivers and well-maintained buses, ensuring a smooth ride to your destination.</p>

<h2>Pune Airport Tempo Traveller on Rent</h2>
<p>Our Pune airport Tempo Traveller on rent service is here to help. We provide convenient airport transfers for groups, ensuring a comfortable and stress-free journey from the airport to your destination. With our punctual and professional drivers, you can rest assured that you’ll arrive at your hotel or event on time.</p>

              </div>

        

              <div>
      <h1>Pune Tempo Traveller Hire for Outstation with Shiv Travels66 Contact Number: - </h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania on Rent in Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- Outstation Tempo Traveller in Pune</p>
<p>- Tempo Traveller 12-Seater Rent Per Km in Pune</p>
<p>- Pune Tempo Traveller Hire for Outstation</p>
<p>- Luxury Tempo Traveller on Rent in Pune</p>
<p>- Luxury Tempo Traveller on Rent in Pune Near Me</p>

<p>- Tempo Traveller on Rent in Pune Rate Per Km</p>

<p>- 20-Seater Tempo Traveller on Rent in Pune</p>



          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- 13-Seater Tempo Traveller on Rent in Pune</p>
<p>- empo Traveller on Rent in Pune for Picnic</p>

<p>- 17-Seater Tempo Traveller on Rent in Pune</p>

<p>- Pune Mumbai Tempo Traveller Hire</p>

<p>- Pune Darshan Tempo Traveller Hire</p>

<p>- 17-Seater Bus on Rent in Pune</p>

<p>- Pune Airport Tempo Traveller on Rent</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h1>Why Choose Us for Pune Tempo Traveller Hire for Outstation with Shiv Travels66?</h1>
<p>When planning an outstation trip, choosing the right transportation is crucial for a comfortable and hassle-free experience. Here’s why Shiv Travels66 stands out for Pune Tempo Traveller hire for outstation journeys:</p>

<ol>
    <li><strong>Diverse Fleet of Vehicles:</strong> We offer a wide range of Tempo Travellers in various seating capacities—9, 12, 17, and 20-seater—to accommodate groups of all sizes. Whether you're traveling with family, friends, or colleagues, we have the perfect vehicle for your needs.</li>
    <li><strong>Comfort and Convenience:</strong> Our Tempo Travellers are designed for comfort, equipped with reclining seats, ample legroom, and air conditioning. You can relax and enjoy the journey, no matter the distance.</li>
    <li><strong>Professional Drivers:</strong> All our drivers are experienced, licensed, and knowledgeable about the best routes and local attractions. They prioritize your safety and comfort, ensuring a smooth ride throughout your journey.</li>
    <li><strong>Affordable Rates:</strong> At Shiv Travels66, we offer competitive pricing with no hidden costs. Our rental packages are designed to provide great value for your money, making it easy to stick to your budget.</li>
    <li><strong>Flexible Booking Options:</strong> We understand that travel plans can change. That’s why we offer flexible booking options, including one-way and round-trip rentals, as well as customizable itineraries that cater to your specific travel needs.</li>
    <li><strong>Well-Maintained Vehicles:</strong> Our fleet is regularly serviced and thoroughly checked to ensure safety and reliability. We prioritize your safety by providing well-maintained vehicles for every trip.</li>
    <li><strong>Excellent Customer Support:</strong> Our dedicated customer support team is available 24/7 to assist you with any inquiries or concerns. We strive to provide a seamless booking experience and are always here to help.</li>
    <li><strong>Customizable Itineraries:</strong> Whether you want to explore popular tourist spots or visit off-the-beaten-path locations, we can create a customized itinerary that fits your preferences, ensuring you get the most out of your trip.</li>
    <li><strong>Positive Customer Feedback:</strong> We take pride in our satisfied customers. Our testimonials speak for the quality of our service, reliability, and the enjoyable experiences we provide to travelers.</li>
    <li><strong>Commitment to Safety:</strong> Your safety is our top priority. We adhere to all safety regulations and protocols, ensuring that you have a secure and pleasant journey.</li>
</ol>

    </div>
      
    <div className="faq-container">
    <h2> FAQs for Shiv Travels66 “Pune Tempo Traveller Hire for Outstation "?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 “Pune Tempo Traveller Hire for Outstation ":- </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text} </a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
        

        <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>


        </div>
      </section>
    </>
  );
}
