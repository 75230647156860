import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import  "./css/animate.css"
import  "./css/bootstrap.css"
import  "./css/color.css"
import  "./css/elpath.css"
import  "./css/flaticon.css"
import  "./css/font-awesome-all.css"
import  "./css/jquery.fancybox.min.css"
import  "./css/nice-select.css"
import  "./css/owl.css"
import  "./css/responsive.css"
import  "./css/rtl.css"
import  "./css/switcher-style.css"
import  "./css/style.css"
import  "./css/module-css/about.css"
import  "./css/module-css/banner.css"
import  "./css/module-css/blog-details.css"
import  "./css/module-css/booking-form.css"
import  "./css/module-css/cart.css"
import  "./css/module-css/category.css"
import  "./css/module-css/chooseus.css"
import  "./css/module-css/clients.css"
import  "./css/module-css/contact-info.css"
import  "./css/module-css/contact.css"
import  "./css/module-css/destination-details.css"
import  "./css/module-css/destinations.css"
import  "./css/module-css/error.css"
// import  "./css/module-css/faq.css"
import  "./css/module-css/instagram.css"
import  "./css/module-css/news-sidebar.css"
import  "./css/module-css/news.css"
import  "./css/module-css/offers.css"
import  "./css/module-css/page-title.css"
import  "./css/module-css/place.css"
import  "./css/module-css/pricing.css"
import  "./css/module-css/subscribe.css"
import  "./css/module-css/testimonial.css"
import  "./css/module-css/tour-details.css"
import  "./css/module-css/video.css"
import  "./css/module-css/tours.css"
import  "./css/module-css/tour-sidebar.css"
import './Navbar.css';
import './App.css'

// ===================
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Fleets from "./Fleets";
import Packages from "./Packages";
import Services from "./Services";
import About from "./About";
import Enquiry from "./Enquiry";
import Contact from "./Contact";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UrbaniaOnRentInPune from "./UrbaniaOnRentInPune";
// import FAQAccordion from "./FAQAccordian";
import PunetomahabaleshwarUrbania from "./PunetomahabaleshwarUrbania";
import Punetoshirdiurbaniaonrent from "./Punetoshirdiurbaniaonrent";
import UrbaniaHirePuneOutstation from "./UrbaniaHirePuneOutstation";
import UrbaniaHireForCorporateEventInPune from "./UrbaniaHireForCorporateEventInPune";
import TempoTravellerOnRentInPune from "./TempoTravellerOnRentInPune";
import PuneToShirdiTempoTravellerOnRent from "./PuneToShirdiTempoTravellerOnRent";
import PuneTempoHireOnOutstationInPune from "./PuneTempoHireOnOutstationInPune";
import TempoTravellerHireForWeddingInPune from "./TempoTravellerHireForWeddingInPune";
import MinibusonRentInPune from "./MinibusonRentInPune";
import InnovaCrystaOnRentInPune from "./InnovaCrystaOnRentInPune";
import PuneToMahabalwshwarInnovaCrystaCab from "./PuneToMahabalwshwarInnovaCrystaCab";
import PuneToShirdiInnovaCrystaCab from "./PuneToShirdiInnovaCrystaCab";
import InnovaCrystaCab from "./InnovaCrystaCab";
import OnlineCabbookinginPune from "./OnlineCabbookinginPune";
import InnovaOnRentInPune from "./InnovaOnRentInPune";
import ScrollToTop from "./ScrollToTop";
import Policy from "./Policy";
import Terms from "./Terms";
import ForceUrbania from "./ForceUrbania";
import InnovaCrysta from './InnovaCrysta';
import PunetoMumbai from './PunetoMumbai';
import PunetoGoa from './PunetoGoa';
import PunetoUjjain from './PunetoUjjain';
import Gallery from "./Gallery";
import Video from "./Video";
import Carousel from "./Carousel";


function App() {
  return (
    <>  
       <Router>
        <ScrollToTop></ScrollToTop>
       <Header></Header>
        <Routes>
             <Route path="/" element={<Home></Home>}></Route>
            <Route path="/fleets" element={<Fleets></Fleets>}></Route>
            <Route path="/Urbania-on-rent-in-pune" element={<UrbaniaOnRentInPune></UrbaniaOnRentInPune>}></Route>
            {/* <Route path="/faq" element={<FAQAccordion></FAQAccordion>}></Route> */}
            <Route path="/Pune-to-Mahabaleshwar-Urbania-on-Rent" element={<PunetomahabaleshwarUrbania></PunetomahabaleshwarUrbania>}></Route>
            <Route path="/Pune-to-Shirdi-Urbania-on-Rent" element={<Punetoshirdiurbaniaonrent></Punetoshirdiurbaniaonrent>}></Route>
            <Route path="/Urbania-Hire-for-Outstation-Pune" element={<UrbaniaHirePuneOutstation></UrbaniaHirePuneOutstation>}></Route>
            <Route path="/Urbania-Hire-for-Corporate-Events-in-Pune" element={<UrbaniaHireForCorporateEventInPune></UrbaniaHireForCorporateEventInPune>}></Route>
            <Route path="/Tempo-Traveller-On-Rent-in-Pune" element={<TempoTravellerOnRentInPune></TempoTravellerOnRentInPune>}></Route>
            <Route path="/Pune-to-Shirdi-Tempo-Traveller-on-Rent" element={<PuneToShirdiTempoTravellerOnRent></PuneToShirdiTempoTravellerOnRent>}></Route>
            <Route path="/Pune-Tempo-Traveller-Hire-for-Outstation" element={<PuneTempoHireOnOutstationInPune></PuneTempoHireOnOutstationInPune>}></Route>
            <Route path="/Tempo-Traveller-Hire-for-Wedding-in-Pune" element={<TempoTravellerHireForWeddingInPune></TempoTravellerHireForWeddingInPune>}></Route>
            <Route path="/Mini-Bus-On-Rent-in-Pune" element={<MinibusonRentInPune></MinibusonRentInPune>}></Route>
            <Route path="/Innova-Crysta-on-Rent-in-Pune" element={<InnovaCrystaOnRentInPune></InnovaCrystaOnRentInPune>}></Route>
            <Route path="/Pune-to-Mahabaleshwar-Innova-Crysta-Cab" element={<PuneToMahabalwshwarInnovaCrystaCab></PuneToMahabalwshwarInnovaCrystaCab>}></Route>
            <Route path="/Pune-to-Shirdi-Innova-Crysta-Cab" element={<PuneToShirdiInnovaCrystaCab></PuneToShirdiInnovaCrystaCab>}></Route>
            <Route path="/Innova-Crysta-Cab" element={<InnovaCrystaCab></InnovaCrystaCab>}></Route>
            <Route path="/Online-Cab-Booking-Pune" element={<OnlineCabbookinginPune></OnlineCabbookinginPune>}></Route>
            <Route path="/Innova-On-Rent-in-Pune" element={<InnovaOnRentInPune></InnovaOnRentInPune>}></Route>
            <Route path="/policy" element={<Policy></Policy>}></Route>
            <Route path="/terms" element={<Terms></Terms>}></Route>
            <Route path="/contact" element={<Contact></Contact>}></Route>
            <Route path="/services" element={<Services></Services>}></Route>
            <Route path="/packages" element={<Packages></Packages>}></Route>
            <Route path="/enquiry" element={<Enquiry></Enquiry>}></Route>
            <Route path="/about" element={<About></About>}></Route>
            <Route path="/forceurbania" element={<ForceUrbania></ForceUrbania>}></Route>
            <Route path="/Innova-Crysta" element={<InnovaCrysta></InnovaCrysta>}></Route>
            <Route path="/Pune-to-Mumbai-Urbania-on-Rent" element={<PunetoMumbai></PunetoMumbai>}></Route>
            <Route path="/Pune-to-Goa-Urbania-On-Rent" element={<PunetoGoa></PunetoGoa>}></Route>
            <Route path="/Pune-to-Ujjain-Urbania-On-Rent" element={<PunetoUjjain></PunetoUjjain>}></Route>
            <Route path="/gallery" element={<Gallery></Gallery>}></Route>
            <Route path="/video" element={<Video></Video>}></Route>
            <Route path="/carousel" element={<Carousel></Carousel>}></Route>
        </Routes>
 
       <Footer></Footer>
       </Router>
    </>
  );
}

export default App;
