import "./App.css";
import React, { useState } from 'react'
import Slider from "react-slick";
import './FAQ.css';
import './Keyword.css';
import { Helmet } from "react-helmet";

export default function UrbaniaOnRentInPune() {
    const [activeIndex, setActiveIndex] = useState(null); // State to track the active index

    const handleToggle = (index) => { // Function to handle toggling of FAQ items
      setActiveIndex(activeIndex === index ? null : index);
    };
    const testimonials = [
  {
    quote: "We recently hired the Urbania for our family trip from Pune to Mahabaleshwar, and it was an incredible experience! The bus was spacious and comfortable, making our journey enjoyable for everyone. Our driver was professional and knowledgeable, ensuring we arrived safely and on time. I highly recommend Shiv Travels66 for anyone looking to travel outstation with a group!",

    name: " Mrs. Riya Patil"
  },
  {
    quote: "Our corporate team used Shiv Travels66 for an outstation trip to Lonavala, and we couldn't be happier with the service. The Urbania bus was immaculate and well-equipped, which added to our comfort during the trip. The staff was very accommodating, and the booking process was smooth. We'll definitely be using their services again for future outings!",

    name: "Mr. Amit Joshi"
  }
];


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true // This adds next/prev arrows
  };


    const faqData = [
      {
        question: "What is an Urbania bus?",
        answer: "An Urbania bus is a luxury minibus designed for comfortable group travel. It features spacious seating, air conditioning, and modern amenities, making it ideal for both local and outstation trips."
      },
      {
        question: "How many passengers can an Urbania bus accommodate?",
        answer: "Our Urbania buses can comfortably seat up to 17 passengers, making them perfect for family outings, corporate trips, or group travels."
      },
      {
        question: "How do I book an Urbania bus for outstation travel?",
        answer: "You can book an Urbania bus by contacting us through our website or by calling our customer service. Provide your travel details, and our team will assist you in finalizing your booking."
      },
      {
        question: "What destinations can I travel to with the Urbania bus?",
        answer: "You can travel to any outstation destination from Pune, including popular places like Mahabaleshwar, Lonavala, Shirdi, Mumbai, and more. Just let us know your preferred destination!"
      },
      {
        question: "Are there any additional charges for outstation trips?",
        answer: "Yes, outstation trips may incur additional charges based on the distance traveled and the duration of the hire. We will provide a clear and transparent pricing structure during the booking process."
      },
      {
        question: "Is the Urbania bus equipped with amenities?",
        answer: "Absolutely! Our Urbania buses are equipped with air conditioning, comfortable seating, charging ports, and entertainment systems, ensuring a pleasant travel experience for all passengers."
      },
      {
        question: "What if I need to make changes to my booking?",
        answer: "If you need to make changes to your booking, please contact us as soon as possible. We will do our best to accommodate your requests based on availability."
      },
      {
        question: "Are your drivers experienced and licensed?",
        answer: "Yes, all our drivers are experienced, licensed, and well-trained. They are knowledgeable about the best routes and prioritize safety throughout your journey."
      },
      {
        question: "Can I hire an Urbania bus for a one-way trip?",
        answer: "Yes, you can hire an Urbania bus for a one-way trip. Just let us know your travel plans, and we will provide you with the necessary arrangements and pricing."
      },
      {
        question: "What safety measures are in place for outstation travel?",
        answer: "We prioritize safety and ensure that all our vehicles are regularly maintained and inspected. Our drivers follow all safety protocols, and we adhere to local regulations to provide a secure travel experience."
      },
      {
        question: "What are the available seating options for Urbania on rent in Pune?",
        answer: "Shiv Travels66 offers Urbania vehicles with 10-seater, 13-seater, and 17-seater options, making them ideal for small to medium-sized groups."
      },
      {
        question: "How can I book an Urbania on rent in Pune?",
        answer: "You can easily book an Urbania by calling us at [Your Contact Number], visiting our website, or sending us an inquiry through our contact form. We’ll confirm your booking and provide all the necessary details."
      }
      
      ];

      const hotelImages = [
        {
          place: "img/Pune-to-Mahabaleshwar-Urbania-on-Ren.jpg",
          text: "Pune to Mahabaleshwar Urbania on Rent",
          path:'Pune-to-Mahabaleshwar-Urbania-on-Rent'
        },
        {
          place: "img/Urbania-Hire-for Corporate-Events in-Pune.jpg",
          text: "Urbania Hire for Corporate Events in Pune",
          path:'Urbania-Hire-for-Corporate-Events-in-Pune'
        },
        {
          place: "img/Innova-Crysta Cab.jpg",
          text: "Inova Crysta Cab",
          path:'Innova-Crysta-Cab'
        },
        {
          place: "img/Innova-Crysta-on Rent-in-Pune.jpg",
          text: "Innova Crysta on Rent in Pune",
          path:'Innova-On-Rent-in-Pune'
        },
        {
            place: "img/Urbania-Hire-for Outstation-Pune.jpg",
            text: "Urbania Hire for Outstation in Pune",
            path:'Urbania-Hire-for-Outstation-Pune'
          },
          {
            place: "img/Tempo-Traveller On-Rent-in-Pune.jpg",
            text: "Tempo Traveller On Rent in Pune",
            path:'Tempo-Traveller-On-Rent-in-Pune'
          },
          {
            place: "img/Pune to Goa.jpg",
            text: "Pune to Goa Urbania on Rent",
            path:'Pune-to-Goa-Urbania-On-Rent'
          },
         
    ]
    const schemaData = {
      "@context": "https://schema.org",
      "@type": "TravelAgency",
      "name": "Shiv Travels66",
      "description": "Explore the best travel packages and destinations with Shiv Travels66. Affordable rates and unforgettable experiences await you!",
      "url": "https://www.shivtravels66.com",
      "logo": "https://www.shivtravels66.com/Logo sizes-01.jpg",
      "address": {
          "@type": "PostalAddress",
          "streetAddress": "Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey No. 51/3A1",
          "addressLocality": "Kirtane Baug Hadpasar",
          "addressRegion": "Maharashtra",
          "postalCode": "411036",
          "addressCountry": "India"
      },
      "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9764768866",
          "contactType": "Customer Service",
          "availableLanguage": "English"
      },
      "email": "booking@shivtravels66.com",
      telephone:"+91 9764768866",
      image: "https://www.shivtravels66.com/OG Image-01.jpg",
      starRating: {
        "@type": "Rating",
        bestRating: "4.8",
        ratingValue: "3",
        worstRating: "1",
      },
      "keywords": [
        "Urbania On Rent in Pune",
        "Pune to Mahabaleshwar Urbania on Rent",
        "Pune to Shirdi Urbania on Rent",
        "Urbania Hire for Outstation Pune",
        "Urbania Hire for Corporate Events in Pune"
    ]
  
  };

   const vehicles = [
    {
      type: ' Force Urbania ',
      capacity: '13-Seater/ 17-Seater',
      rates: 'Rs.10,800',
      perDay: 'Rs.36/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book  Force Urbania 13-Seater/ 17-Seater With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
    {
      type: 'Tempo Traveller AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.7,800',
      perDay: 'Rs.26/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Tempo Traveller Non-AC',
      capacity: '13-Seater, 17-Seater',
      rates: 'Rs.6,900',
      perDay: 'Rs.23/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Tempo Traveller Non-AC With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    }, 
    {
      type: 'Innova Crysta',
      capacity: '6+1 Seater',
      rates: 'Rs.5,700',
      perDay: '19/ Per Km',
      driverDA: 'Rs.400',
      booking: 'Available',
      path:'https://api.whatsapp.com/send/?phone=919764768866&text=I want to Book Innova Crysta With Shiv Travels Request you Please Send More Details or Call Me Thank you!!'
    },
  ];
  
  
   
  return (
    <>

<Helmet>
                <title>Contact:+91 9764768866|Shiv Travels|Home|Urbania On Rent in Pune | Pune to Mahabaleshwar Urbania on Rent|  Pune to Shirdi Urbania on Rent
                | Urbania Hire for Outstation Pune | Tempo Traveller On Rent in Pune- Shiv Travels66</title>
                <meta name="description" content="Relax on the beautiful beaches with our special packages - Shiv Travels" />
                
                {/* Open Graph Metadata */}
                <meta property="og:title" content="Comfort and relax free travel with Shiv travel - Shiv Travels66" />
                <meta property="og:description" content="Enjoy a relaxing $ safely Travel with Shiv Travels66 ." />
                <meta property="og:image" content="https://www.shivtravels66.com/OG Image-01.jpg" /> 
                <meta property="og:url" content="https://www.shivtravels66.com/home" />
                <meta property="og:type" content="website" />
                <meta name="keywords" content=" Urbania On Rent in Pune,Pune to Mahabaleshwar Urbania on Rent,Pune to Shirdi Urbania on Rent Urbania Hire for Outstation Pune" />
        <meta name="robots" content="index, follow" />
                {/* Additional Open Graph Metadata */}
                <meta property="og:site_name" content="Shiv Travels66" />
                <meta property="og:phone_number" content="+91 9764768866" />
                
                <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
            </Helmet>

     
      {/* <!-- page-title --> */}
      <section class="page-title centred car-back1">
        <div
          class="bg-layer"
          style={{
            "background-image": "url(images/background/page-title-2.jpg);",
          }}
        ></div>
        <div class="auto-container">
          <div class="content-box">
            <span>Shiv Travels66</span>
            <h1>Urbania Hire for Outstation Pune</h1>
          </div>
        </div>
      </section>
      {/* <!-- page-title end --> */}

      {/* ============keyword based section ================= */}
      <section className="mysection keyword-page">
        <div className="container">
          <div class="row d-flex container">
            <div className="col-12 col-md-8">
              <div>
                <img src="img/Urbania-Hire-for Outstation-Pune.jpg" alt="Urbania-Hire-for Outstation-Pune" />
              </div>

              <div>
              <h1>Urbania Hire for Outstation Pune with Shiv Travels66</h1>
<p>When planning an outstation trip from Pune, comfort and convenience should be your top priorities. At Shiv Travels66, we offer Urbania hire for outstation Pune, providing a luxurious travel experience that ensures you reach your destination safely and in style. Our Urbania buses are perfect for families, groups, and corporate outings, making your journey memorable and enjoyable.</p>

<h2>17-Seater Urbania AC Bus On Rent in Pune</h2>
<p>Are you planning a group trip and need spacious transportation? Look no further than our 17-Seater Urbania AC Bus on rent in Pune! Designed for comfort and luxury, our Urbania buses are perfect for family outings, corporate events, and group travel. With air conditioning, plush seating, and ample storage space, you can enjoy a smooth journey without feeling cramped. Contact Shiv Travels66 today to book your Urbania bus and experience a hassle-free travel experience in Pune!</p>

<h2>Urbania on Rent for Outstation</h2>
<p>Traveling outstation from Pune? Our Urbania on rent for outstation service provides the perfect solution for your group travel needs. Whether you're heading to scenic hill stations, spiritual destinations, or business trips, our Urbania buses offer a comfortable and reliable mode of transportation. Equipped with modern amenities and driven by experienced chauffeurs, you can relax and enjoy the ride. Make your outstation trip memorable with Shiv Travels66 and book your Urbania today!</p>

<h2>Book Luxury Force Urbania on Rent in Pune – Shiv Travels</h2>
<p>Experience luxury travel like never before with our Luxury Force Urbania on rent in Pune! At Shiv Travels66, we pride ourselves on providing high-quality transportation that meets all your travel needs. Our Urbania buses are perfect for any occasion, whether it's a family vacation, a corporate outing, or a pilgrimage. With spacious interiors, air conditioning, and entertainment systems, your comfort is guaranteed. Don’t miss out—contact us now to book your Luxury Force Urbania and enjoy a premium travel experience!</p>

<h2>Pune to Lonavala Urbania Bus on Rent</h2>
<p>Planning a getaway to Lonavala? Our Pune to Lonavala Urbania bus on rent service ensures a comfortable journey to this popular hill station. With stunning views along the way, our Urbania buses provide a spacious and relaxing travel experience for your group. Whether you're visiting for leisure or a special occasion, you can count on Shiv Travels66 for timely service and a smooth ride. Book your Urbania bus now for a memorable trip to Lonavala!</p>

<h2>Pune to Mumbai Airport Urbania Bus on Rent</h2>
<p>Our Pune to Mumbai Airport Urbania bus on rent service is here to help! Enjoy a hassle-free journey with ample space for luggage and comfortable seating. Our experienced drivers ensure you arrive at the airport on time, alleviating the stress of travel. Choose Shiv Travels66 for your airport transfer needs and experience our top-notch service. Book your Urbania today for a seamless travel experience!</p>

<h2>Urbania Rent in Pune</h2>
<p>Shiv Travels66 offers a variety of options tailored to your travel requirements. Our Urbania buses are perfect for both local and outstation travel, providing comfort, luxury, and convenience. Whether it’s a short trip within the city or a longer journey to popular destinations, our buses are equipped to handle all your travel needs. Contact us to learn more about our Urbania rental services and book your bus today!</p>

<h2>Pune to Mumbai Force Urbania Bus on Rent</h2>
<p>Choose our Force Urbania bus on rent for a comfortable and spacious journey! At Shiv Travels66, we offer well-maintained Urbania buses that can accommodate your entire group with ease. Our buses come equipped with air conditioning, comfortable seating, and modern amenities to ensure a pleasant travel experience. Whether you're heading for business or leisure, our professional drivers will navigate the roads efficiently, allowing you to relax and enjoy the ride. Book your Pune to Mumbai Force Urbania today for an unforgettable journey!</p>

<h2>Urbania Hire for Picnic Pune</h2>
<p>Look no further! Our Urbania hire for picnic Pune service is perfect for family outings, friends’ get-togethers, or corporate picnics. With spacious interiors and comfortable seating, our Urbania buses provide a relaxing environment for everyone. Enjoy the scenic views as you travel to popular picnic spots around Pune, such as Mulshi, Pawna Lake, or Lonavala. At Shiv Travels66, we ensure a hassle-free booking process and excellent service, so you can focus on making memories. Hire your Urbania for a picnic today!</p>

<h2>Force Urbania Hire for Local Picnic Pune</h2>
<p>Enjoy a delightful day out with our Force Urbania hire for local picnic Pune service. Ideal for small to medium-sized groups, our Urbania buses offer ample space and comfort for your local picnic trips. Whether you’re heading to a nearby park or a scenic location, our experienced drivers will take you there safely and efficiently. With Shiv Travels66, you can expect a reliable and enjoyable travel experience. Don’t wait—book your Urbania for your next local picnic adventure today!</p>

<h2>Urbania in Pune for Local Trip</h2>
<p>Exploring Pune? Our Urbania in Pune for local trip service is the best choice for comfortable and spacious transportation. Whether you’re visiting tourist attractions, attending events, or just exploring the city with friends or family, our Urbania buses are designed to make your local trips enjoyable. Equipped with all the necessary amenities, our buses ensure a pleasant travel experience. Contact Shiv Travels66 to hire your Urbania for your next local trip and discover Pune in style!</p>

<h2>Hire Urbania Bus on Rent with Shiv Travels</h2>
<p>Shiv Travels66 is your go-to provider for all your transportation needs in Pune. Our Urbania buses are perfect for group outings, local trips, picnics, and outstation journeys. With a focus on comfort and reliability, we offer competitive pricing and a seamless booking process. Our professional drivers and well-maintained vehicles ensure that you have a smooth travel experience. Don’t hesitate—hire your Urbania bus with us today and experience travel like never before!</p>

              </div>

              <section className="container text-center table-section">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
           <a
             href="https://api.whatsapp.com/send/?phone=919764768866&text=."
             target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn"
           >
             Book Now
           </a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>




              <div>
      <h1>Urbania Hire for Outstation Pune with Shiv Travels66 Contact Number</h1>
      <p>
        Contact Shiv Travels66 at <a href="tel:+919764768866">+91 9764768866</a> for prompt and efficient Urbania Hire for Outstation Pune. We ensure a smooth and enjoyable ride for all our customers.
      </p>
      <table
      style={{
        borderCollapse: "collapse",
        width: "100%",
        borderWidth: "0px",
      }}
      border="1"
    >
      <colgroup>
        <col style={{ width: "49.9491%" }} />
        <col style={{ width: "49.9491%" }} />
      </colgroup>
      <tbody>
        <tr>
          <td style={{ borderWidth: "0px" }}>
          <p>- 17 Seater Urbania AC Bus On Rent in Pune</p>
          <p>- Book Luxury Force Urbania on rent in Pune </p>
         <p>- Pune to Lonavala Urbania bus on rent </p>
          <p>- Urbania Rent In Pune </p>
         <p>- Urbania hire for picnic Pune </p>
          <p>- Urbania in Pune for local trip </p>
          </td>
          <td style={{ borderWidth: "0px" }}>
          <p>- urbania on rent in for outstation</p>
          <p>– Shiv Travels, Shiv Travels Pune</p>
          <p>- Pune to Mumbai airport Urbania bus on rent</p>
          <p>- Pune to Mumbai Force Urbania bus on rent</p>
          <p>- Force Urbania hire for local picnic Pune</p>
          <p>- Hire Urbania bus on Rent with Shiv Travels</p>
          </td>
        </tr>
      </tbody>
    </table>
      
    </div>

    <div>
    <h2>Why Choose Us for Urbania Hire for Outstation Pune with Shiv Travels66?</h2>

<p>When planning an outstation trip from Pune, you want a transportation service that prioritizes comfort, safety, and reliability. At Shiv Travels66, we understand your travel needs and strive to exceed your expectations. Here’s why you should choose us for your Urbania hire for outstation Pune:</p>

<h3>Comfort and Space</h3>
<p>Our Urbania buses are designed with spacious interiors and plush seating, ensuring that every passenger enjoys a comfortable ride. Whether you’re traveling with family, friends, or colleagues, you’ll have plenty of room to relax and enjoy the journey.</p>

<h3>Modern Amenities</h3>
<p>Each Urbania comes equipped with modern features such as air conditioning, entertainment systems, and charging ports, providing a pleasant travel experience. Stay refreshed and entertained throughout your trip, no matter the distance.</p>

<h3>Experienced and Professional Drivers</h3>
<p>Safety is our top priority. Our drivers are not only skilled and experienced but also knowledgeable about the best routes and road conditions. You can trust them to navigate efficiently, ensuring you arrive at your destination safely and on time.</p>

<h3>Flexible Itineraries</h3>
<p>We understand that every trip is unique. With Shiv Travels66, you have the flexibility to customize your itinerary according to your preferences. Whether you want to make additional stops or explore local attractions along the way, we accommodate your needs.</p>

<h3>Competitive Pricing</h3>
<p>Enjoy premium travel without breaking the bank! We offer competitive rates for our Urbania hire services, ensuring you get the best value for your money. You can travel in style without compromising your budget.</p>

<h3>24/7 Customer Support</h3>
<p>Our commitment to customer satisfaction goes beyond transportation. We provide round-the-clock customer support to address any inquiries or concerns before, during, or after your trip. You can count on us for assistance at any time.</p>

<h3>Seamless Booking Process</h3>
<p>Booking your Urbania for outstation travel is easy and hassle-free. You can reach out to us via our website or phone, and our friendly staff will guide you through the process, making your travel planning straightforward and stress-free.</p>

<h3>Positive Customer Feedback</h3>
<p>Our clients consistently praise us for our punctuality, professionalism, and the quality of our vehicles. We take pride in the trust and loyalty of our customers, and we strive to maintain our high standards of service.</p>

<h3>Local Knowledge</h3>
<p>Our familiarity with the best travel routes and popular destinations ensures a smooth journey. Whether you're heading to scenic hill stations, historic sites, or vibrant cities, we know how to make the most of your trip.</p>

<h3>Commitment to Safety</h3>
<p>We adhere to all safety regulations and protocols to ensure a secure travel experience. Our buses are regularly maintained and undergo thorough inspections to guarantee your safety on the road.</p>

    </div>
      
    <div className="faq-container">
    <h2>FAQs for Urbania Hire for Outstation Pune?</h2>
    {faqData.map((faq, index) => (
      <div key={index}>
        <p
          className="faq-question" 
          onClick={() => handleToggle(index)}
        >
          {faq.question}
        </p>
        {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
      </div>
    ))}
  </div>

  <div className="testimonial-carousel">
      <h4>Testimonials for Shiv Travels66 " Urbania Hire for Outstation Pune "- </h4>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <p className="testimonial-quote">"{testimonial.quote}"</p>
            <p className="testimonial-author">– {testimonial.name}</p>
          </div>
        ))}
      </Slider>
    </div>
       {/* ================================ */}
                <div className="contact-box">
                     <p>For Booking Inquiries or any assistance, please feel free to contact us Contact Information for Travels Service with Shiv Travels!</p>
                     <a href="">
                         <i class="bi bi-geo-alt-fill"></i>
                         Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                     </a>
                     <p>
                     <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill"></i>  +91 9764768866</a>
                     </p>
                     <p>
                     <a href="mailto:booking@shivtravels66.com">
                     <i class="bi bi-envelope "></i>booking@shivtravels66.com</a>
                     </p>
                     
                </div>
            {/* ===================== */}
            </div>
            <div className="col-12 col-md-4">
              <h2>Recent Post</h2>
              {hotelImages.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href={e.path}
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href={e.path}>{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className="">
                <h5 className="pb-3 pt-3">Contact Information</h5>

                <div className="services__two-item">
                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="tel:+91 7058638153">
                      <i class="bi bi-telephone-fill fs-1"></i>
                      <div>
                        <a href="tel:+91 9764768866"> +91 9764768866</a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                    <a href="  mailto:booking@shivtravels66.com">
                      <i class="bi bi-envelope fs-1"></i>
                      <div className="">
                        <a href="  mailto:booking@shivtravels66.com">
                        booking@shivtravels66.com
                  </a>
                      </div>
                    </a>
                  </div>

                  <div className="mybox1 text-center p-3 mb-3">
                  <a href="./">
                    <i class="bi bi-house-fill fs-1"></i>
                    <p>
                    Shiv Travels 66,
               Flat No. 1603 Building WING C SUKHWANI HERMOSA CASA Survey,
               No. 51/3A1, Kirtane Baug Hadpasar, 
               Mundhwa - Kharadi Road, Mundhwa,
               Pune, Maharashtra -411036.
                    </p>
                  </a>
                </div>

             
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 container-fluid">
          <section className="container text-center table-section ">

<h5><span className="colorblue">Customized Packages for Every Explorer Outstation Capacity</span>
</h5>
<div className="table-responsive mt-4">
<table className="table table-striped">
 <thead>
   <tr>
     <th>Type Of Vehicle</th>
     <th>Outstation Capacity</th>
     <th>Outstation Rates</th>
     <th>Outstation Per Day (300km)</th>
     <th>Per Day Driver D.A.</th>
     <th>Outstation Booking</th>
   </tr>
 </thead>
 <tbody>
   {vehicles.map((vehicle, index) => (
     <tr key={index}>
       <td data-label="Type Of Vehicle">{vehicle.type}</td>
       <td data-label="Outstation Capacity">{vehicle.capacity}</td>
       <td data-label="Outstation Rates">{vehicle.rates}</td>
       <td data-label="Outstation Per Day (300km)">{vehicle.perDay}</td>
       <td data-label="Per Day Driver D.A.">{vehicle.driverDA}</td>
       <td>
         <div className="">
  

           <a href={vehicle.path} target="_blank"
             rel="noopener noreferrer"
             className="btn fleet-btn">Book Now</a>
         </div>
       </td>
     </tr>
   ))}
 </tbody>
</table>
</div>
</section>
</div>

        </div>
      </section>
    </>
  );
}
